import * as React from "react";
import MainCategory from "./MainCategory";

const MainCategories = ({items: categories, setSelectedItem}) => {
    return (
        <div className={'mt-1'}>
            <div className={'w-full relative overflow-x-auto whitespace-nowrap no-scrollbar px-[25px] flex h-[130px]'}>
                { categories && (
                    categories.map(category => {
                        return (
                            <div className={'w-[100px] m-[5px] '} onClick={() => setSelectedItem(category)} key={'category-' + category.id}>
                                <MainCategory category={category}/>
                            </div>
                        )
                    })
                )}
            </div>
        </div>
    )
}
export default MainCategories;