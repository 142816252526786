import {useCallWaiterMutation} from "../storage/api";
import {useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBell} from "@fortawesome/free-regular-svg-icons";
import ConfirmDialog from "./muiComponents/ConfirmDialog";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import toast from "react-hot-toast";

const WaiterCallButton = () => {
  const [callWaiter,] = useCallWaiterMutation()
  const {tableId, menuInfo} = useSelector(state => state.app)
  const [dialogOpen, setDialogOpen] = useState(false);
  const {t} = useTranslation();

  if (tableId && menuInfo.can_call_waitstaff)
    return (
      <>
        <div
          className={'fixed bottom-0 mb-[60px] z-50 flex justify-center w-full items-center text-black left-0'}>
          {/*<div className={'animate-ping bg-black h-[45px] w-[25px] absolute rounded-tl-full rounded-bl-full z-0'}/>*/}
          <button onClick={() => setDialogOpen(true)}
                  className={'bg-white rounded-r-full rounded-l-full flex justify-center p-3 items-center z-10 shadow-2xl drop-shadow-2xl'}>
            <div className={'rounded-full bg-primary p-1 mr-3 w-[36px] h-[36px] flex justify-center items-center'}>
              <FontAwesomeIcon icon={faBell} size={'lg'} className={'text-white'}/>
            </div>
            {t('general.call_waitstaff')}
          </button>
        </div>
        <ConfirmDialog open={dialogOpen} onClose={() => setDialogOpen(false)}
                       message={t('general.waitstaff_confirm_message')}
                       onApprove={async () => {
                         const result = await callWaiter(tableId)
                         if ('error' in result) {
                           toast.error(t('general.error'))
                           console.log(result)
                         } else {
                           toast.success(t('general.calling_waitstaff_successful'))
                         }
                       }}/>
      </>
    )
}

export default WaiterCallButton