import {SwipeableDrawer} from "@mui/material";
import {useModal} from "../../utils/modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClock} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {TranslatedAttribute} from "../../../../utils";
import AllergenIcon from "../AllergenIcon";
import Puller from "./Puller";
import ProductForm from "../Products/ProductForm";

const ProductDetailModal = () => {
  const modal = useModal();
  const {locale} = useSelector(state => state.app);
  const {t} = useTranslation();
  const {isOrderAvailable} = useSelector(state => state.app);
  const [open, setOpen] = useState(false);

  const close = () => {
    setOpen(false)
    // setTimeout(destroyModal, 1000)
  }

  useEffect(() => {
    setOpen(true)
  }, [])

  return (
    <>
      {
        <SwipeableDrawer
          BackdropProps={{style: {backgroundColor: 'rgba(159, 166, 144, 0.6)'}}}
          minFlingVelocity={250}
          PaperProps={{
            sx: {
              backgroundColor: "transparent",
              boxShadow: 'none'
            },
          }}
          anchor={'bottom'}
          open={open}
          onOpen={() => {
          }}
          onClose={close}
          transitionDuration={{exit: 600, appear: 0, enter: 600}}
          allowSwipeInChildren={true}
        >
          <Puller/>
          <div className={'w-full max-w-[420px] mx-auto h-full rounded-t-[30px] p-4 relative bg-secondary'}>
            <div
              className={'overflow-scroll rounded-xl no-scrollbar max-h-[70dvh] flex flex-col justify-start items-center mt-10 relative'}>
              {
                modal.data.full_size_url ? (
                  <div className={'mb-4'}>
                    <img src={modal.data.full_size_url} alt="" className={'w-full max-w-fit rounded-xl'}/>
                  </div>
                ) : (
                  <></>
                )
              }

              <div className={'flex flex-row my-2'}>
                {
                  (modal.data.ingredient_tags).map((tag, idx) => {
                    return (
                      <AllergenIcon key={`${idx}-${tag}`} tag={tag}/>
                    )
                  })
                }
              </div>

              <div className={'w-full items-center text-center'}>
                <div className={'text-[20pt]'}>{TranslatedAttribute(locale, modal.data, 'name')}</div>
                <div className={'font-normal text-[12px] mb-2'}>
                  {TranslatedAttribute(locale, modal.data, 'description')}
                </div>
                <>
                  {
                    isOrderAvailable && modal.data.is_available ? (
                      <div>
                        <ProductForm item={modal.data}/>
                        {
                          modal.data.preparation_time && (
                            <div
                              className={'text-center flex items-center justify-center bg-[#f8f8f8] rounded-xl mt-4'}>
                                                            <span
                                                              className={'m-2 text-sm'}>{modal.data.preparation_time} {t('general.minute')} </span>
                              <FontAwesomeIcon icon={faClock}
                                               className={'text-[#808080]'}/>
                            </div>
                          )
                        }

                      </div>
                    ) : (
                      <></>
                    )
                  }

                </>
              </div>

              {
                modal.data.note ? (
                    <div className={'bg-blue-100 px-4 py-1 rounded-xl text-xs mt-3'}>
                      {TranslatedAttribute(locale, modal.data, 'note')}
                    </div>
                  ) :
                  (
                    <></>
                  )
              }

            </div>
          </div>
        </SwipeableDrawer>
      }
    </>
  )
}

export default ProductDetailModal;