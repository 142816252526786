import {Outlet} from "react-router-dom";
import {useSelector} from "react-redux";
import {useEffect} from "react";

const ImageDefaultLayout = () => {
  const {themeOption} = useSelector(state => state.app);

  useEffect(() => {
    import(`./assets/css/${themeOption}.css`)
  }, [themeOption]);

  return (
    <div className={'max-w-[430px] mx-auto h-[100dvh] pb-0 overflow-y-scroll no-scrollbar relative'}>
      <Outlet/> {/* Route'lar buraya renderlanacak */}
    </div>
  )
}

export default ImageDefaultLayout;