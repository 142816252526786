import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setAnswersDestroy} from "../../../../storage/feedback";

const Textarea = ({id}) => {
    const [textVal, setTextVal] = useState('');
    const {answers} = useSelector(state => state.feedback);
    const dispatch = useDispatch();

    useEffect(() => {
        answers.forEach(answer => {
           if(answer?.qr_menu_feedback_question_id === id){
               let index = answers.indexOf(answer);
               const newArr = Object.assign([...answers, {'qr_menu_feedback_question_id': id, is_required: answer.is_required, answer: textVal}], answers);
               newArr.splice(index, 1);
               dispatch(setAnswersDestroy(newArr));
           }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [textVal])

    return(
        <div className={'flex flex-row justify-center'}>
                <textarea id="message"
                          value={textVal}
                          onChange={e => setTextVal(e.target.value)}
                          className="resize-none block p-2.5 w-full text-sm text-gray-900 rounded-lg border border-gray-300"/>
        </div>
    )
}

export default Textarea;