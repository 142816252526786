import {Drawer} from "@mui/material";
import {destroyModal} from "../../utils/modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleXmark} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {useTranslation} from "react-i18next";
import {setIsCookieAccept} from "../../../../storage/app";
import toast from "react-hot-toast";
import {useDispatch} from "react-redux";

const CookiePreferences = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    return (
        <>
            {
                <Drawer
                    PaperProps={{
                        sx: {
                            backgroundColor: "transparent",
                            boxShadow: 'none'
                        }
                    }}
                    anchor={'bottom'}
                    open={true}
                    onClose={destroyModal}
                >

                    <div className={'w-full max-w-[420px] mx-auto bg-[#fff] h-[50%] rounded-t-[30px] p-4 relative'}>
                        <FontAwesomeIcon icon={faCircleXmark} size={'2x'} className={'text-black w-[40px] h-[40px] absolute'} style={{ right: 5, top: 5 }} onClick={destroyModal}/>

                        <div className={'overflow-scroll no-scrollbar h-[350px] flex flex-col justify-start items-center py-6 mt-6'}>
                            <div className={'flex flex-col justify-center items-center'}>
                                <span className={'text-[#555555] text-[20px] font-bold text-center mb-2'}>{t('general.cookie_title')}</span>
                                <p className={'text-[#555555] text-[15px] text-center mb-2'}>{t('general.cookie_text')}</p>
                                <div className={'flex flex-row items-center justify-between my-4'}>
                                    <div onClick={() => {
                                        dispatch(setIsCookieAccept(true))
                                        toast.success(t('general.your_choice_saved'))
                                    }} className={'bg-black text-[#fff] m-2 py-2 px-3 rounded-xl'}>{t('general.accept')}</div>
                                    <div onClick={() => {
                                        dispatch(setIsCookieAccept(false))
                                        toast.success(t('general.your_choice_saved'))
                                    }} className={'bg-black text-[#fff] m-2 py-2 px-3 rounded-xl'}>{t('general.reject')}</div>
                                </div>

                                <span className={'text-[#555555] text-[20px] font-bold text-center mb-2'}>{t('general.cookie_info')}</span>
                                <p className={'text-[#555555] text-[15px] text-center mb-2'}>{t('general.cookie_info_text')}</p>

                            </div>
                        </div>
                    </div>
                </Drawer>
            }
        </>
    )
}

export default CookiePreferences;