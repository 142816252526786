import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faHouse} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from "react-router-dom";

const HomeButton = () => {
    const navigate = useNavigate();

    return (
        <div onClick={() => navigate('/home')} className={'flex flex-row items-center'}>
            <FontAwesomeIcon icon={faChevronLeft} color={'#8d3d3d'} className={'font-bold m-1 w-[20px] h-[20px]'} />
            <FontAwesomeIcon icon={faHouse} color={'#555555'} className={'font-bold m-1 w-[25px] h-[25px]'} />
        </div>
    )
}

export default HomeButton;