import {Drawer} from "@mui/material";
import {useModal} from "../../utils/modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleXmark} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {TranslatedAttribute} from "../../../../utils";
import AllergenIcon from "../../../ImageDefault/components/AllergenIcon";
import ProductForm from "../Product/ProductForm";

const ProductDetailModal = () => {

  const modal = useModal();
  const {currencySymbol, locale, isOrderAvailable} = useSelector(state => state.app);
  const {t} = useTranslation();
  const [open, setOpen] = useState(false);
  const close = () => {
    setOpen(false);
  }
  useEffect(() => {
    setOpen(true)
  }, []);
  return (
    <>
      {
        <Drawer
          PaperProps={{
            sx: {
              backgroundColor: "transparent",
              boxShadow: 'none'
            }
          }}
          anchor={'bottom'}
          open={open}
          onOpen={() => {
          }}
          onClose={close}
        >

          <div className={'w-full max-w-[420px] mx-auto bg-[#fff] h-full rounded-t-[30px] p-4 relative'}>
            <FontAwesomeIcon icon={faCircleXmark} size={'2x'}
                             className={'no-image-text-secondary w-[40px] h-[40px] absolute'} style={{right: 5, top: 5}}
                             onClick={close}/>
            <div
              className={'overflow-scroll no-scrollbar max-h-[540px] flex flex-col justify-start items-center mt-10 relative'}>

              {
                isOrderAvailable && (
                  <div className={'w-full flex flex-col justify-center text-center'}>
                    <span className={'text-[22px] font-bold text-[#555555] mb-2'}>{t('general.add_to_cart')}</span>
                    <span className={'text-[16px] text-[#555555] mb-5'}>{t('general.product_detail_sub_title')}</span>
                  </div>
                )
              }

              <div className={'flex flex-row my-2'}>
                {
                  (TranslatedAttribute(locale, modal.data, 'ingredient_tags')).map((tag, idx) => {
                    return (
                      <AllergenIcon key={`${idx}-${tag}`} tag={tag}/>
                    )
                  })
                }
              </div>

              {
                !isOrderAvailable ? (
                  <div className={'w-full flex flex-row items-center justify-between'}>

                    <div className={'flex flex-col'}>
                      <span className={'font-bold'}>{TranslatedAttribute(locale, modal.data, 'name')}</span>
                      <span
                        className={'font-normal text-[12px] mb-2'}>{TranslatedAttribute(locale, modal.data, 'description')}</span>
                      <span>{(`${(modal.data?.price[currencySymbol]).toFixed(2) + ' ' + currencySymbol}`)}</span>
                    </div>

                  </div>
                ) : (
                  <ProductForm item={modal.data}/>
                )

              }
              {
                modal.data.note ? (
                    <div className={'bg-blue-100 px-4 py-1 rounded-xl text-xs mt-3'}>
                      {TranslatedAttribute(locale, modal.data, 'note')}
                    </div>
                  ) :
                  (
                    <></>
                  )
              }

            </div>
          </div>
        </Drawer>
      }
    </>
  )
}

export default ProductDetailModal;