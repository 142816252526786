import {Tooltip} from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import {useState} from "react";
import {useSelector} from "react-redux";

const AllergenIcon = ({tag}) => {
    const [open, setOpen] = useState(false);
    const {locale} = useSelector(state => state.app);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    return (
        <ClickAwayListener onClickAway={handleTooltipClose}>
            <div>
                <Tooltip
                    followCursor
                    PopperProps={{
                        disablePortal: true,
                    }}
                    onClose={handleTooltipClose}
                    open={open}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={tag.name[locale]}
                >
                    <img src={require(`../../assets/img/allergen-icons/${tag.icon}`)} alt="" onClick={handleTooltipOpen} className={'w-[35px] m-[2px]'}/>
                </Tooltip>
            </div>
        </ClickAwayListener>
    )
}

export default AllergenIcon;