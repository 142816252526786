import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import {Provider} from "react-redux";
import {store, persistedStore} from "./storage/store";
import i18n from './i18n';
import {I18nextProvider} from "react-i18next";
import {PersistGate} from "redux-persist/integration/react";
import {ErrorBoundary, Provider as RollbarProvider} from "@rollbar/react";

const root = ReactDOM.createRoot(document.getElementById('root'));
const rollbarConfig = {
    accessToken: '68e6b21e060e4671a25e254213ee5024',
    environment: process.env.NODE_ENV,
};

root.render(
    <RollbarProvider config={rollbarConfig}>
        <ErrorBoundary>
            <Provider store={store}>
                <PersistGate loading={<div>Loading</div>} persistor={persistedStore}>
                    <I18nextProvider i18n={i18n} defaultNS={'translation'}>
                        <BrowserRouter basename={process.env.PUBLIC_URL}>
                            <App />
                        </BrowserRouter>
                    </I18nextProvider>
                </PersistGate>
            </Provider>
        </ErrorBoundary>
    </RollbarProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
