import {TranslatedAttribute} from "../../../../utils";
import React from "react";
import Collapsible from "../../../../components/muiComponents/Collapsible";
import CheckBox from "../../../../components/form/CheckBox";
import {useSelector} from "react-redux";
import Counter from "../../../../components/form/Counter";
import Variant from "./Variant";
import {useTranslation} from "react-i18next";

const ProductForm = ({formData, data, calc_total_price, handleInputChange}) => {
  const {locale, currencySymbol} = useSelector(state => state.app);
  const {t} = useTranslation();

  const handleChange = (e) => {
    let val
    try {
      val = JSON.parse(e.value)
    } catch (e) {
      val = e.value
    } finally {
      handleInputChange(e.name, val, e.checked)
    }
    calc_total_price()
  }

  if (data && formData)
    return (
      <>
        {
          data.variants && data.variants.length > 0 && (
            <>
              <h4 className={'text-left my-2'}>{t('general.required_selections')}</h4>
              {
                data.variants.map(variant => {
                  return (
                    <Collapsible name={TranslatedAttribute(locale, variant, 'name')}>
                      <Variant variant={variant} onChange={handleChange} max={variant.max}/>
                    </Collapsible>
                  )
                })
              }
            </>
          )
        }
        {
          data.options && Object.entries(data.options).length > 0 && (
            <>
              <h4 className={'text-left my-2'}>{t('general.optional_selections')}</h4>
              {
                Object.entries(data.options).map(([name, options]) => {
                  return (
                    <Collapsible key={`option-group.${name}`} name={name}>
                      {options.map(option => {
                        return (
                          <CheckBox key={`options.${option.id}`}
                                    name={`options.${option.id}`}
                                    value={JSON.stringify(option)}
                                    default_checked={option.default}
                                    onChange={handleChange}
                                    label={option.name}
                                    label_plus={`+${option.price || 0} ${currencySymbol}`}>
                            <Counter name={`options.${option.id}.amount`}
                                     onChange={handleChange}
                                     iconDisabledClassNames={'text-[#e4e4e4]'}
                                     iconClassNames={'text-[#000]'}/>
                          </CheckBox>
                        )
                      })}
                    </Collapsible>
                  )
                })
              }
            </>
          )

        }
      </>
    )
}

export default ProductForm