import React, {useCallback, useEffect, useState} from "react";

/**
 * Bu form component'ini kullanirken bir kac prop almaktadir.
 *
 * Kullanim amaclari su sekildedirler:
 ** formData: Formunuzdaki degisikliklerin tutulacagi state
 ** name: formData'da hangi alanda tutulacagi (ornek: "user.profile.active" -> {user: {profile: {active: ...}}})
 ** value: input field'in value deep. formData state'ine ne yazilacagi.
 ** default_checked: baslangicta secili olup olmayacagi
 ** validation: Islem yapilabilmesi icin gereken validasyon fonksiyonu (boolean deger dondurmeli)
 ** onUpdate: input field degistiginde calisacak ekstra fonksiyon.
 ** content: checkbox'in yaninda gozukecek yazi
 ** content_plus: checkbox'in sonunda gozukecek yazi
 */

const CheckBox = ({
                    name = '',
                    label = '',
                    label_plus = '',
                    value = '',
                    default_checked = false,
                    children,
                    onChange = () => {
                    },
                    disabled = false,
                    wrapperClassnames = 'w-full rounded-lg p-2 bg-[#f8f8f8] shadow-md mb-4',
                    labelClassnames = 'col-span-9 grid grid-cols-11',
                    inputWrapperClassnames = 'col-span-1',
                    labelContentClassnames = 'ml-2 col-span-7 text-left ',
                    labelContentPlusClassNames = 'col-span-3 flex justify-center',
                  }) => {
  const [checked, setChecked] = useState(false);

  const handleChange = useCallback((e) => {
    if (!disabled || checked) {
      setChecked(e.target.checked)
    } else {
      e.target.checked = false
      e.target.maxError = true
    }
    onChange(e.target)
  }, [checked, disabled, onChange])

  useEffect(() => {
    if (default_checked) {
      setChecked(default_checked)
      const event = {
        target: {
          name: name,
          value: value,
          checked: default_checked
        }
      }
      handleChange(event)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [default_checked, name, value]);

  const onChildChange = (e) => {
    if (e.value === 0) {
      setChecked(false)
      const event = {
        target: {
          name: name,
          value: value,
          checked: false
        }
      }
      handleChange(event)
    }
  }
  return (
    <div
      className={wrapperClassnames}>
      <label className={labelClassnames}>
        <div className={inputWrapperClassnames}>
          <input
            name={name}
            type="checkbox"
            checked={checked}
            onChange={handleChange}
            value={value}/>
        </div>

        <span
          className={labelContentClassnames + (checked ? 'line-clamp-2' : 'line-clamp-1')}>
            {label}
          </span>
        <span className={labelContentPlusClassNames}>
            {label_plus}
          </span>
      </label>

      {
        checked && children && (
          <>
            {
              React.cloneElement(children, {
                onChange: (e) => {
                  onChildChange(e)
                  children.props.onChange(e)
                }
              })
            }
          </>
        )
      }
    </div>
  )
}

export default CheckBox;