import {combineReducers, configureStore} from "@reduxjs/toolkit";
import app from "./app";
import drawerMenu from "./drawermenu";
import {api} from "./api";
import {setupListeners} from "@reduxjs/toolkit/query";
import {persistReducer, persistStore} from "redux-persist";
import thunk from "redux-thunk";
import createWebStorage from "redux-persist/es/storage/createWebStorage";
import feedback from "./feedback";
import loader from "./loader";

const sessionStorage = createWebStorage('session')

const persistConfig = {
    key: 'qr-menu-v2',
    storage: sessionStorage,
    whitelist: ['app']
}

const rootReducer = combineReducers({
    app,
    drawerMenu,
    feedback,
    loader,
    [api.reducerPath]: api.reducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
    reducer: persistedReducer,
    middleware: [thunk, api.middleware]
    // middleware: (getDefaultMiddleware) =>
    //     getDefaultMiddleware().concat(api.middleware).concat(thunk),
})


setupListeners(store.dispatch)

// export default store;
export const persistedStore = persistStore(store)