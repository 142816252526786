import BasketModal from "./BasketModal";
import LanguageModal from "./LanguageModal";
import HomeInfoModal from "./HomeInfoModal";
import WiFiModal from "./WiFiModal";
import CookiePreferencesModal from "./CookiePreferencesModal";

const modals = [

    {
        name: 'theme_3_basket',
        element: BasketModal
    },
    {
        name: 'theme_3_language',
        element: LanguageModal
    },
    {
        name: 'home_info',
        element: HomeInfoModal
    },
    {
        name: 'wifi_modal',
        element: WiFiModal
    },
    {
        name: 'cookie_preferences',
        element: CookiePreferencesModal
    },
];

export default modals;