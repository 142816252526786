export const tr_translations = {
  translation: {
    menu: {
      go_menu: 'Menüye Git',
      select_lang: 'Dilinizi Seçin',
      suggestions_of_the_moment: 'Anın Önerileri',
      wifi: 'Wi-Fi',
      cookie_preferences: 'Çerez Ayarları',
      feedback_form: 'Geri Bildirim Formu',
      google_rating: "Bize Google'da Yorum Yapın"
    },
    general: {
      yes: "Evet",
      no: "Hayır",
      waitstaff_confirm_message: "Garson çağırmak istediğinize emin misiniz?",
      call_waitstaff: "Garson Çağır",
      calling_waitstaff_successful: "Bildiriminiz iletildi.",
      error: "Hata!",
      basket: "Sepet",
      my_basket: "Sepetim",
      basket_text: "Sepetinizdeki Ürünleri Sipariş Verebilirsiniz.",
      no_product: "Sepetinizde Ürün Bulunmamaktadır.",
      order: "Sipariş Ver",
      clear_basket: "Sepeti Temizle",
      add_to_cart: "Sepete Ekle",
      product_detail_sub_title: "Ürünleri sepetinize ekleyerek sipariş verebilirsiniz..",
      total: "Toplam",
      save: "Kaydet",
      cancel: "İptal",
      name: "İsim",
      note: "Not",
      send: "Gönder",
      required_field: "Alanı Zorunlu Seçimdir!",
      successfully_added_to_cart: "Ürün başarıyla sepetinize eklendi!",
      successfully_submitted: "Başarılı Bir Şekilde İletildi!",
      please_required_fields: "Lütfen Zorunlu Alanları Doldurunuz!",
      name_field_cannot_empty: "İsim Alanı Boş Bırakılamaz!",
      determine_the_product_quantity: "Ürün Miktarını Belirleyiniz.",
      maximum_number_of_selections: "Maksimum Seçim Adedi:",
      minimum_number_of_selections: "{{item}} İçin Minimum Seçim Adedi {{quantity}}",
      ssid: "Wifi İsmi",
      password: "Şifre",
      cookie_title: "Çerez Politikası",
      cookie_text: "Lütfen çerezlerin toplanmasıyla ilgili tercihinizi belirtiniz.",
      cookie_info: "Bilgilendirme",
      cookie_info_text: "Çerezler sistemimizdeki deneyiminizi iyileştirir.",
      accept: "Kabul Et",
      reject: "Reddet",
      your_choice_saved: "Tercihiniz Kaydedildi!",
      ok: 'Tamam',
      allow_location_access: 'Konum Erişimine İzin Veriniz',
      you_can_order_if_you_allow_location_access: 'Konum erişimine izin vermeniz halinde sipariş verebilirsiniz!',
      search_all_products: 'Tüm Ürünlerde Arayın...',
      description: 'Açıklama',
      all_items_delete_in_basket: 'Sepetteki Tüm Ürünler Silinmek Üzere!',
      delete: 'Sil',
      successful: 'Başarılı!',
      delete_the_product: 'Ürünü Silmek Üzeresiniz!',
      order_failed: 'Sipariş Verilemedi!',
      outside_order_border: 'Sipariş Verebilme Sınırları Dışında Olduğunuz için Sipariş Verilemedi!',
      order_transmitted: 'Sipariş İletildi!',
      enjoy_your_meal: 'Siparişiniz Bize Ulaştı. Afiyet Olsun!',
      minute: 'Dk',
      select: 'Seçim',
      required_selections: "Zorunlu Seçimler",
      optional_selections: "İsteğe Bağlı Seçimler",
    }
  }
}