import {useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowUp, faBasketShopping} from "@fortawesome/free-solid-svg-icons";
import {createModal} from "../../utils/modal";
import {useTranslation} from "react-i18next";

const BasketButton = () => {
    const {basket} = useSelector(state => state.app)
    const {t} = useTranslation();

    return (
        <div
            onClick={() => createModal('italian_basket')}
            className={( basket.length === 0 ? 'bg-[#fff] italian-text-secondary' : 'italian-bg-secondary text-[#fff]') +' h-[55px] w-full flex flex-row justify-between items-center rounded-t-2xl fixed bottom-0 left-0 z-[999] text-center py-3 px-7 font-bold'}>
            <span>
                <FontAwesomeIcon icon={faBasketShopping} style={{ marginRight: 10 }}/>
                {t('general.basket')}  ({ basket.items.length })
            </span>
            <FontAwesomeIcon icon={faArrowUp} />
        </div>
    );
}

export default BasketButton;