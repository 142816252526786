import * as React from 'react';
import List from '@mui/material/List';
import CollapseHead from "./CollapseHead";

const Category = ({items}) => {

    return (
        <>
            <List
                sx={{ width: '100%', marginTop: 1 }}
                component="nav"
                aria-labelledby="nested-list-subheader"
            >
                {
                    items ? (
                        items.map( item => {
                                return (
                                    <div key={item.id} className={'m-1'}>
                                        <CollapseHead key={item.id} item={item} extraStyle={'bg-[#f2f9f8]'} />
                                    </div>
                                )
                            }
                        )
                    ):(
                        <></>
                    )
                }

            </List>
        </>
    );

}

export default Category;