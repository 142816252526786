import CheckBox from "../../../../components/form/CheckBox";
import {getSelectedOption, TranslatedAttribute} from "../../../../utils";
import SelectBox from "../../../../components/form/SelectBox";
import React, {useState} from "react";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import toast from "react-hot-toast";

const Variant = ({
                   variant,
                   onChange = () => {
                   },
                   max = null
                 }) => {
  const [checkedCount, setCheckedCount] = useState(0);
  const {locale, currencySymbol} = useSelector(state => state.app)
  const {t} = useTranslation()


  const maxValidation = () => {
    return checkedCount + 1 > max;
  }

  const handleChange = (e) => {
    if (checkedCount + 1 > max && e.maxError) {
      toast.error(t('general.maximum_number_of_selections') + ' ' + max);
      e.maxError = false
    } else {
      if (e.checked) {
        setCheckedCount(checkedCount + 1);
      } else {
        setCheckedCount(checkedCount - 1);
      }
      onChange(e)
    }
  }
  return (
    <>
      {variant.selection_type === 'multi' ? (
        variant.options.map(option => {
          return <CheckBox default_checked={option.default}
                           name={`variants.${variant.id}.${option.id}`} value={JSON.stringify(option)}
                           key={option.id}
                           label={TranslatedAttribute(locale, option, 'name')}
                           onChange={handleChange}
                           label_plus={`+${option.additional_price || 0} ${currencySymbol}`}
                           disabled={maxValidation()}
          />
        })
      ) : (
        <>
          <SelectBox name={`variants.${variant.id}`}
                     options={variant.options.map(option => {
                       return {
                         value: JSON.stringify({[option.id]: option}),
                         label: option.name,
                         label_plus: `+${option.additional_price} ${currencySymbol}`
                       }
                     })}
                     onChange={onChange} key={'selectbox-' + variant.id}
                     selectedOption={JSON.stringify(getSelectedOption(variant.options, 'default', true))}/>
        </>
      )}
    </>
  )
}

export default Variant