import {Drawer} from "@mui/material";
import {destroyModal} from "../../utils/modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleXmark} from "@fortawesome/free-solid-svg-icons";
import React from "react";

const AllergenListModal = () => {
    const list = [
        {
            "id": 1,
            "icon": "ALLERGEN_1.png",
            "name": "Gluten"
        },
        {
            "id": 2,
            "icon": "ALLERGEN_2.png",
            "name": "Shellfish"
        },
        {
            "id": 3,
            "icon": "ALLERGEN_3.png",
            "name": "Eggs"
        },
        {
            "id": 4,
            "icon": "ALLERGEN_4.png",
            "name": "Pisces"
        },
        {
            "id": 5,
            "icon": "ALLERGEN_5.png",
            "name": "Peanuts"
        },
        {
            "id": 6,
            "icon": "ALLERGEN_6.png",
            "name": "Soy"
        },
        {
            "id": 7,
            "icon": "ALLERGEN_7.png",
            "name": "Milk"
        },
        {
            "id": 8,
            "icon": "ALLERGEN_8.png",
            "name": "Nuts"
        },
        {
            "id": 9,
            "icon": "ALLERGEN_9.png",
            "name": "Celery"
        },
        {
            "id": 10,
            "icon": "ALLERGEN_10.png",
            "name": "Mustard"
        },
        {
            "id": 11,
            "icon": "ALLERGEN_11.png",
            "name": "Sesame Seed"
        },
        {
            "id": 12,
            "icon": "ALLERGEN_12.png",
            "name": "Suplhites"
        },
        {
            "id": 13,
            "icon": "ALLERGEN_13.png",
            "name": "Lupine"
        },
        {
            "id": 14,
            "icon": "ALLERGEN_14.png",
            "name": "Molluscs"
        }
    ];

    return (
        <>
            {
                <Drawer
                    PaperProps={{
                        sx: {
                            backgroundColor: "transparent",
                            boxShadow: 'none'
                        }
                    }}
                    anchor={'bottom'}
                    open={true}
                    onClose={destroyModal}
                >

                    <div className={'w-full max-w-[420px] mx-auto bg-[#fff] h-[80%] rounded-t-[30px] p-6 relative'}>
                        <FontAwesomeIcon icon={faCircleXmark} size={'2x'} className={'image-text-primary w-[40px] h-[40px] absolute'} style={{ right: 5, top: 5 }} onClick={destroyModal}/>

                        <div className={'overflow-scroll no-scrollbar h-[600px] flex flex-col justify-start items-center py-6 mt-6'}>

                            {
                                list.map(item => {
                                    return (
                                        <div key={item.id} className={'w-full px-3 py-1 mb-4 rounded-[20px] bg-[#f8f8f8] shadow-md flex flex-row items-center'}>
                                            <img src={require(`../../assets/img/allergen-icons/${item.icon}`)} alt="" className={'w-[40px] h-[40px] m-2'}/>
                                            <span className={'ml-3'}>{ item.name }</span>
                                        </div>
                                    )
                                })
                            }

                        </div>
                    </div>
                </Drawer>
            }
        </>
    )
}


export default AllergenListModal;