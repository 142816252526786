import * as React from "react";
import {useEffect, useState} from "react";
import Header from "../../components/Header/Header";
import Loader from "../../components/Loader";
import {useGetFeedbackFormQuery, useSendFeedbackFormMutation} from "../../../../storage/api";
import Textarea from "../../components/FeedbackForm/Textarea";
import Emoji from "../../components/FeedbackForm/Emoji";
import YesNo from "../../components/FeedbackForm/YesNo";
import Star from "../../components/FeedbackForm/Star";
import {useDispatch, useSelector} from "react-redux";
import {setAnswers} from "../../../../storage/feedback";
import {useTranslation} from "react-i18next";
import toast from "react-hot-toast";
import {useNavigate} from "react-router-dom";
import {AnimatePresence, motion as m} from "framer-motion";


const NoImageDefaultFeedbackForm = () => {

    const dispatch = useDispatch();
    const { data, isLoading } = useGetFeedbackFormQuery();
    const [, setItems] = useState();
    const [questions, setQuestions] = useState({});
    const { answers } = useSelector(state => state.feedback)
    const [sendFeedbackForm] = useSendFeedbackFormMutation()
    const {t} = useTranslation()
    const navigate = useNavigate();

    useEffect(() => {
        setItems(data);
        setQuestions(data?.qr_menu_feedback_questions)
        data?.qr_menu_feedback_questions.forEach(question => {
            let arr = {
                qr_menu_feedback_question_id: question.id,
                is_required: question.is_required,
                answer: null
            }
            dispatch(setAnswers(arr))
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading])


    const feedbackForm = () => {
        let sendData = {
            qr_menu_feedback_form_id: data.id,
            qr_menu_feedback_answers_attributes: answers
        };

        sendFeedbackForm(sendData)
            .unwrap()
            .then((response) => {
                if(response.success){
                    toast.success(t('general.successfully_submitted'), {
                        position: "top-center",
                        style: {
                            border: '1px solid #02796b',
                            padding: '16px'
                        },
                    });
                    navigate('/home');
                }else{
                    toast.error(t('general.please_required_fields'), {
                        position: "bottom-center",
                        style: {
                            border: '1px solid #02796b',
                            padding: '16px',
                            color: '#8f0202',
                        },
                    });
                }

            })
            .then((error) => {
                console.log(error)
            })
    }

    return (
        <m.div key={'feedback'}
               initial={{ opacity: 0 }}
               animate={{ opacity: 1 }}
               transition={{ duration: 0.3, ease: "easeOut" }}
               exit={{ opacity: 0 }}
               className={'no-scrollbar pb-[75px] w-[95%] mx-auto'}>
            <Header isShowHomeButton={true} />

            <div className={'flex flex-row'}>

                {
                    questions && (
                        <div className={'w-full flex flex-col justify-start'}>
                            {
                                Object.values(questions).map((item, index) => {
                                    return (
                                        <div key={item.id}>
                                            <div className={'my-3 font-bold text-[#fff]'}>
                                                {item.question}
                                                {
                                                    item.is_required ? <span className={'text-red-700'}> *</span> : <></>
                                                }

                                            </div>
                                            {
                                                item.answer_type === 'yes_no' ?
                                                    <YesNo id={item.id} />
                                                : item.answer_type === 'text' ?
                                                    <Textarea id={item.id} />
                                                : item.answer_type === 'star' ?
                                                    <Star id={item.id} />
                                                : item.answer_type === 'emoji' ?
                                                    <Emoji id={item.id} />
                                                : <></>
                                            }
                                        </div>

                                    )
                                })
                            }

              <button className={'bg-primary text-[#fff] border border-white p-3 mt-8 rounded-xl font-bold text-[20px]'}
                      onClick={() => feedbackForm()}>
                {t('general.send')}
              </button>
            </div>
          )
        }
        {
          !questions && (
            <AnimatePresence>
              {
                (questions == null || questions.length === 0) && (
                  <m.div
                    key={'loading'}
                    initial={{opacity: 1}}
                    transition={{duration: 0.3, ease: "easeOut"}}
                    exit={{opacity: 0}}>
                    <Loader/>
                  </m.div>
                )
              }
            </AnimatePresence>
          )
        }
      </div>

        </m.div>
    )
}

export default NoImageDefaultFeedbackForm;