import LanguageModal from "./LanguageModal";
import HomeInfoModal from  "./HomeInfoModal";
import AllergenListModal from "./AllergenListModal";
import ProductDetailModal from "./ProductDetailModal";
import BasketModal from "./BasketModal";
import CookiePreferencesModal from "./CookiePreferencesModal";
import WiFiModal from "./WiFiModal";

const modals = [
    {
        name: 'no_image_language',
        element: LanguageModal
    },
    {
        name: 'no_image_home_info',
        element: HomeInfoModal
    },
    {
        name: 'no_image_allergen_list',
        element: AllergenListModal
    },
    {
        name: 'no_image_product_detail',
        element: ProductDetailModal
    },
    {
        name: 'no_image_basket',
        element: BasketModal
    },
    {
        name: 'no_image_cookie_preferences',
        element: CookiePreferencesModal
    },
    {
        name: 'no_image_wifi_modal',
        element: WiFiModal
    }
];

export default modals;