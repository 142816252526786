import modalArray from "./modals";
import {useModal} from "../../utils/modal";
const Modals = () => {

    const modal = useModal();

    const CreateModal = () => {
        const createModal = modalArray.find(m => m.name === modal.name);
        if(createModal){
            return <createModal.element />
        }
    }

    return (
        <>
            <CreateModal />
        </>
    )
}

export default Modals;