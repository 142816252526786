import {Drawer} from "@mui/material";
import {createModal, destroyModal} from "../../utils/modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleXmark, faCookieBite, faWifi} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {
    faFacebook,
    faFoursquare,
    faInstagram,
    faTiktok,
    faTwitter,
    faYoutube
} from "@fortawesome/free-brands-svg-icons";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

const HomeInfoModal = () => {
    const {menuInfo} = useSelector(state => state.app)
    const {t} = useTranslation();

    const goToSocialLink = (socialMedia) => {
        let username = menuInfo?.social_media[socialMedia];
        if(socialMedia === 'facebook'){
            window.open('https://www.facebook.com/'+username, '_blank');
        }else if(socialMedia === 'instagram'){
            window.open('https://www.instagram.com/'+username, '_blank');
        }else if(socialMedia === 'twitter'){
            window.open('https://www.x.com/'+username, '_blank');
        }else if(socialMedia === 'youtube'){
            window.open('https://www.youtube.com/@'+username, '_blank');
        }else if(socialMedia === 'tiktok'){
            window.open('https://www.tiktok.com/@'+username, '_blank');
        }else if(socialMedia === 'foursquare'){
            window.open('https://www.foursquare.com/v/'+username, '_blank');
        }
    }

    return (
        <>
            {
                <Drawer
                    PaperProps={{
                        sx: {
                            backgroundColor: "transparent",
                            boxShadow: 'none'
                        }
                    }}
                    anchor={'bottom'}
                    open={true}
                    onClose={destroyModal}
                >

                    <div className={'w-full max-w-[420px] mx-auto bg-[#fff] h-[350px] rounded-t-[30px] p-4 relative'}>
                        <FontAwesomeIcon icon={faCircleXmark} size={'2x'} className={'text-black w-[40px] h-[40px] absolute'} style={{ right: 5, top: 5 }} onClick={destroyModal}/>

                        <div className={'overflow-scroll no-scrollbar grid grid-cols-2 h-[300px] flex justify-center items-center gap-2 pb-6 mt-6'}>

                            <div onClick={() => createModal('cookie_preferences')} className={'h-[140px] overflow-hidden py-3 rounded-[10px] bg-[#f8f8f9] flex flex-col justify-center items-center m-2 shadow-md'}>
                                <div className={'bg-black w-[55px] h-[55px] p-2 rounded-full mb-2 flex justify-center items-center'}>
                                    <FontAwesomeIcon icon={faCookieBite} size={'2x'} color={'#fff'}/>
                                </div>
                                <div className={'text-[#555] text-center'}>{t('menu.cookie_preferences')}</div>
                            </div>

                            <div onClick={() => createModal('wifi_modal')} className={'h-[140px] overflow-hidden py-3 rounded-[10px] bg-[#f8f8f9] flex flex-col justify-center items-center shadow-md'}>
                                <div className={'bg-black w-[55px] h-[55px] p-2 rounded-full mb-2 flex justify-center items-center'}>
                                    <FontAwesomeIcon icon={faWifi} size={'2x'} color={'#fff'}/>
                                </div>
                                <div className={'text-[#555] text-center'}>{t('menu.wifi')}</div>
                            </div>


                            {
                                menuInfo && menuInfo?.social_media ? (
                                    Object.keys(menuInfo?.social_media).map(socialMedia => {
                                        return (
                                            <div key={socialMedia} onClick={() => goToSocialLink(socialMedia)}>
                                                {
                                                    menuInfo?.social_media[socialMedia] !== "" ?

                                                        <div className={'h-[140px] overflow-hidden py-3 rounded-[10px] bg-[#f8f8f9] flex flex-col justify-center items-center m-1 shadow-md'}>
                                                            <div className={'bg-black w-[55px] h-[55px] p-2 rounded-full mb-2 flex justify-center items-center'}>
                                                                {
                                                                    socialMedia === "facebook" ?
                                                                        <FontAwesomeIcon icon={faFacebook} size={'2x'} color={'#fff'}/>
                                                                        : socialMedia === "instagram" ?
                                                                            <FontAwesomeIcon icon={faInstagram} size={'2x'} color={'#fff'}/>
                                                                            : socialMedia === "youtube" ?
                                                                                <FontAwesomeIcon icon={faYoutube} size={'2x'} color={'#fff'}/>
                                                                                : socialMedia === "tiktok" ?
                                                                                    <FontAwesomeIcon icon={faTiktok} size={'2x'} color={'#fff'}/>
                                                                                    : socialMedia === "twitter" ?
                                                                                        <FontAwesomeIcon icon={faTwitter} size={'2x'} color={'#fff'}/>
                                                                                        : socialMedia === "foursquare" ?
                                                                                            <FontAwesomeIcon icon={faFoursquare} size={'2x'} color={'#fff'}/>
                                                                                            : <></>
                                                                }
                                                            </div>
                                                            <div className={'text-[#555] text-center'}>/{ socialMedia }</div>
                                                        </div>
                                                        :
                                                        <></>
                                                }
                                            </div>
                                        )
                                    })
                                ):(
                                    <></>
                                )
                            }

                        </div>
                    </div>
                </Drawer>
            }
        </>
    )
}


export default HomeInfoModal;