import {useSelector} from "react-redux";
import {createModal} from "../../utils/modal";
import React from "react";

const HeaderButton = () => {
    const {locale} = useSelector(state => state.app)

    return (
        <div>
            <div onClick={() => createModal('theme_3_language')} className={'w-[35px] h-[35px] rounded-[35px] ml-2 border-[7px] border-white image-bg-thirdy flex justify-center items-center shadow-md'}>
                <img src={require(`../../../../assets/img/langs/${locale}.png`)} alt="" className={'w-[25px]'}/>
            </div>
        </div>
    )
}

export default HeaderButton;
