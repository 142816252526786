import React from "react";
import SplashScreen from "./pages/SplashScreen";
import NoImageDefaultHome from "./themes/NoImageDefault/pages/Home";
import NoImageDefaultMenu from "./themes/NoImageDefault/pages/Menu";
import ImageDefaultHome from "./themes/ImageDefault/pages/Home";
import NoImageDefaultFeedbackForm from "./themes/NoImageDefault/pages/FeedbackForm";
import ImageDefaultFeedbackForm from "./themes/ImageDefault/pages/FeedbackForm";
import ImageDefaultMenu from "./themes/ImageDefault/pages/Menu";
import Theme3Home from "./themes/Theme3/pages/Home";
import Theme3Menu from "./themes/Theme3/pages/Menu";
import Theme3CategoryDetail from "./themes/Theme3/pages/CategoryDetail";
import Theme3ProductDetail from "./themes/Theme3/pages/ProductDetail";
import Theme4Home from "./themes/Theme4/pages/Home";
import Theme4Menu from "./themes/Theme4/pages/Menu";
import Theme4FeedbackForm from "./themes/Theme4/pages/FeedbackForm";
import ItalianHome from "./themes/Italian/pages/Home";
import ItalianMenu from "./themes/Italian/pages/Menu";
import MainScreen from "./pages/MainScreen";
// Modals //
import NoImageDefaultModals from "./themes/NoImageDefault/components/DrawerModal";
import ImageDefaultModals from "./themes/ImageDefault/components/DrawerModal";
import Theme3Modals from "./themes/Theme3/components/DrawerModal";
import Theme4Modals from "./themes/Theme4/components/DrawerModal";
import ItalianModals from "./themes/Italian/components/DrawerModal";
// Layout //
import Theme4Layout from "./themes/Theme4/Theme4Layout";
import ItalianLayout from "./themes/Italian/ItalianLayout";
import Theme3Layout from "./themes/Theme3/Theme3Layout";
import ImageDefaultLayout from "./themes/ImageDefault/ImageDefaultLayout";
import NoImageDefaultLayout from "./themes/NoImageDefault/NoImageDefaultLayout";


const Elements = {
  "SplashScreen": SplashScreen,
  "NoImageDefaultHome": NoImageDefaultHome,
  "ImageDefaultHome": ImageDefaultHome,
  "NoImageDefaultMenu": NoImageDefaultMenu,
  "NoImageDefaultFeedbackForm": NoImageDefaultFeedbackForm,
  "ImageDefaultMenu": ImageDefaultMenu,
  "ImageDefaultFeedbackForm": ImageDefaultFeedbackForm,
  "Theme3Home": Theme3Home,
  "Theme3Menu": Theme3Menu,
  "Theme3CategoryDetail": Theme3CategoryDetail,
  "Theme3ProductDetail": Theme3ProductDetail,
  "ItalianHome": ItalianHome,
  "ItalianMenu": ItalianMenu,
  "MainScreen": MainScreen,
  "Theme4Home": Theme4Home,
  "Theme4Menu": Theme4Menu,
  "Theme4FeedbackForm": Theme4FeedbackForm,
  // Modals //
  "NoImageDefaultModals": NoImageDefaultModals,
  "ImageDefaultModals": ImageDefaultModals,
  "Theme3Modals": Theme3Modals,
  "ItalianModals": ItalianModals,
  "Theme4Modals": Theme4Modals,
  // Layouts //
  "NoImageDefaultLayout": NoImageDefaultLayout,
  "ImageDefaultLayout": ImageDefaultLayout,
  "Theme3Layout": Theme3Layout,
  "ItalianLayout": ItalianLayout,
  "Theme4Layout": Theme4Layout
};

const ElementItem = (item) => {

  if (typeof Elements[item] !== "undefined") {
    return React.createElement(Elements[item], {
      key: item.id,
      item: item
    });
  }
  return React.createElement(
    () => <div>The component {item} has not been created yet.</div>,
    {key: item.id || item}
  );
}

export default ElementItem