import {SwipeableDrawer} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import Puller from "./Puller";

const WiFiModal = () => {
  const {menuInfo} = useSelector(state => state.app)
  const {t} = useTranslation();
  const [open, setOpen] = useState(false);
  const close = () => {
    setOpen(false)
    // setTimeout(destroyModal, 1000)
  }
  useEffect(() => {
    setOpen(true)
  }, [])

  return (
    <>
      {
        <SwipeableDrawer
          BackdropProps={{style: {backgroundColor: 'rgba(159, 166, 144, 0.6)'}}}
          minFlingVelocity={250}
          PaperProps={{
            sx: {
              backgroundColor: "transparent",
              boxShadow: 'none'
            },
          }}
          anchor={'bottom'}
          open={open}
          onOpen={() => {
          }}
          onClose={close}
          transitionDuration={{exit: 600, appear: 0, enter: 600}}
          allowSwipeInChildren={true}
        >
          <Puller/>
          <div className={'w-full max-w-[420px] mx-auto h-[40%] rounded-t-[30px] p-4 relative bg-secondary'}>
            <div
              className={'overflow-scroll no-scrollbar h-[200px] flex flex-col justify-start items-center py-6 mt-6'}>
              <div className={'flex flex-col justify-center items-center'}>
                <span className={'text-[#555555] text-[20px] font-bold text-center mb-2'}>{t('menu.wifi')}</span>
                <div className={'w-full flex flex-row items-center justify-between my-1'}>
                  <div className={'w-full image-bg-thirdy text-[#555] m-2 py-2 px-3 rounded-xl font-bold'}>
                    <span> {t('general.ssid')}: </span>
                    <span className={'font-normal'}>{menuInfo?.wifi?.ssid || '-'}</span>
                  </div>
                </div>

                <div className={'w-full flex flex-row items-center justify-between my-1'}>
                  <div className={'w-full image-bg-thirdy text-[#555] m-2 py-2 px-3 rounded-xl font-bold'}>
                    <span> {t('general.password')}: </span>
                    <span className={'font-normal'}>{menuInfo?.wifi?.password || '-'}</span>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </SwipeableDrawer>
      }
    </>
  )
}

export default WiFiModal;