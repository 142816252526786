import * as React from "react";
import {useState} from "react";
import Categories from "./Categories";
import {TranslatedAttribute} from "../../../../utils";
import {useSelector} from "react-redux";
import Collapse from "@mui/material/Collapse";

const Category = ({category}) => {
  const [open, setOpen] = useState(false);
  const {locale} = useSelector((state) => state.app);
  const toggleOpen = () => {
    setOpen(!open);
  }
  const scrollToCategory = () => {
    const el = document.getElementById('category-' + category.id);
    el.scrollIntoView({behavior: 'smooth'});
  }

  return (
    <>
      <div id={'category-' + category.id}
           className={'h-[90px] border border-secondary rounded-[12px] relative overflow-hidden bg-primary shadow-md'}
           onClick={toggleOpen}>
        <div
          className={'h-full w-full main-category-title text-[20pt] absolute place-content-center text-center text-white z-10'}>
          {TranslatedAttribute(locale, category, 'name')}
        </div>
        {
          category.wide_image_url && (
            <>
              <div className={'h-full w-full bg-black/[0.35] absolute z-0'}></div>
              <img className={'w-full h-full category-image object-cover'} alt="" src={category.wide_image_url}/>
            </>
          )
        }
      </div>
      <Collapse in={open} onEntered={scrollToCategory}>
        <Categories items={category.sub_items}/>
      </Collapse>
    </>
  )
}

export default Category