import LanguageModal from "./LanguageModal";
import HomeInfoModal from  "./HomeInfoModal";
import AllergenListModal from "./AllergenListModal";
import ProductDetailModal from "./ProductDetailModal";
import BasketModal from "./BasketModal";
import CookiePreferencesModal from "./CookiePreferencesModal";
import WiFiModal from "./WiFiModal";

const modals = [
    {
        name: 'language',
        element: LanguageModal
    },
    {
        name: 'home_info',
        element: HomeInfoModal
    },
    {
        name: 'allergen_list',
        element: AllergenListModal
    },
    {
        name: 'product_detail',
        element: ProductDetailModal
    },
    {
        name: 'basket',
        element: BasketModal
    },
    {
        name: 'cookie_preferences',
        element: CookiePreferencesModal
    },
    {
        name: 'wifi_modal',
        element: WiFiModal
    }
];

export default modals;