import * as React from "react";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useGetAllProductsQuery} from "../../../../storage/api";
import Loader from "../../../Theme3/components/Loader";
import Category from "../../../Theme3/components/Category";
import BasketButton from "../../../Theme3/components/BasketButton";
import DefaultImg from "../../../../assets/img/default-img.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHouse} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from "react-router-dom";
import WaiterCallButton from "../../../../components/WaiterCallButton";

const Menu = () => {
  const [items, setItems] = useState([]);
  const {isOrderAvailable, menuId, menuInfo, tableId} = useSelector(state => state.app)
  const {data, isLoading} = useGetAllProductsQuery(menuId);
  const {loading} = useSelector(state => state.loader)
  const navigate = useNavigate();

  useEffect(() => {
    if (Boolean(data)) {
      setItems(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  return (
    <>
      <div className={'w-full bg-[#000] p-4 pb-[75px] flex justify-between items-center'}>
        <div onClick={() => navigate('/home')}>
          <FontAwesomeIcon icon={faHouse} color={'#fff'} className={'font-bold w-[20px] h-[20px]'}/>
        </div>
        <img className={'rounded-xl h-[45px]'} src={menuInfo?.logo || DefaultImg} alt="Kardo QR Menü"/>
        <div className={'w-[20px] h-[20px]'}>
        </div>
      </div>

      {
        menuInfo.table && (
          <div className={'text-center'}>
            <h3 className={'text-[#555555] text-[15px] font-semibold text-center'}>{menuInfo.table}</h3>
          </div>
        )
      }

      {
        data && data.length > 0 ? (
          <div>
            {
              tableId && menuInfo.can_call_waitstaff &&
              <WaiterCallButton/>
            }
            <Category items={items}/>

            {
              isOrderAvailable === true ? <BasketButton/> : <></>
            }
            {
              loading ? <Loader/> : null
            }
          </div>
        ) : (
          <Loader/>
        )
      }
      {
        tableId && menuInfo.can_call_waitstaff &&
        <div className={'pt-[115px]'}/>
      }
    </>
  )
}

export default Menu;