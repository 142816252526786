import Header from "../../components/Header";
import DefaultImg from "../../../../assets/img/default-img.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faFacebook,
    faFoursquare,
    faInstagram,
    faReadme,
    faTiktok,
    faTwitter,
    faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import {createModal} from "../../utils/modal";
import {faChevronUp, faCookieBite, faWifi} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {TranslatedAttribute} from "../../../../utils";

const Home = () => {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const {isShowFeedbackForm, menuInfo, isGoogleComment, googleCommentUrl, locale} = useSelector(state => state.app)

  return (
    <div className={'h-full flex flex-col justify-center items-center pb-[220px]'}>
      <Header/>

      <div className={'flex flex-col justify-between items-center'}>
        <div className={'mb-5'}>
          <img className={'rounded-xl'} src={menuInfo?.logo || DefaultImg} alt="Kardo QR Menü" width={125}
               height={125}/>
        </div>

        <div className={'text-center mb-10'}>
          {
            TranslatedAttribute(locale, menuInfo, 'title') ?
              <h1
                className={'text-[#555555] text-[30px] font-bold text-center my-3'}>{TranslatedAttribute(locale, menuInfo, 'title')}</h1> : <></>
          }
          <h3
            className={'text-[#555555] text-[15px] font-semibold text-center'}>{TranslatedAttribute(locale, menuInfo, 'sub_title') || ''}</h3>
        </div>

        {
          isGoogleComment === true ?
            (
              <a href={googleCommentUrl} rel={"noreferrer"} target={'_blank'}
                 className={'image-bg-thirdy text-[#555] py-1 px-2 rounded-xl text-[14px]'}>
                {t('menu.google_rating')}
              </a>
            ) : null
        }

        {
          isShowFeedbackForm === true ?
            (
              <div className={'mt-4 image-bg-thirdy text-[#555] py-1 px-2 rounded-xl text-[14px]'}
                   onClick={() => navigate('/feedback')}>
                {t('menu.feedback_form')}
              </div>
            ) : null
        }

        {
          menuInfo.table && (
            <div className={'text-center mt-4'}>
              <h3 className={'text-[#555555] text-[15px] font-semibold text-center'}>{menuInfo.table}</h3>
            </div>
          )
        }
      </div>

      <div
        className={'image-bg-primary w-full h-[180px] rounded-t-[30px] flex flex-col justify-between fixed bottom-0'}
        style={{}}>
        <div onClick={() => navigate('/menu')}
             className={'flex flex-col justify-center items-center cursor-pointer mt-4 mb-2'}>
          <FontAwesomeIcon icon={faReadme} color={'#fff'} size={'3x'}/>
          <span className={'text-white'}>{t('menu.go_menu')}</span>
        </div>

        <div onClick={() => createModal('home_info')}
             className={'bg-[#fff] w-full h-[80px] rounded-t-[30px] text-center pb-4'}>
          <FontAwesomeIcon icon={faChevronUp}/>

          <div className={'w-full mx-auto flex flex-row items-center justify-center'}>
            <div
              className={'h-[42px] w-[42px] rounded-[42px] image-bg-thirdy text-[#555] flex justify-center items-center mx-2'}>
              <FontAwesomeIcon icon={faCookieBite}/>
            </div>
            <div
              className={'h-[42px] w-[42px] rounded-[42px] image-bg-thirdy text-[#555] flex justify-center items-center mx-2'}>
              <FontAwesomeIcon icon={faWifi}/>
            </div>
            {
              menuInfo && menuInfo?.social_media ? (
                Object.keys(menuInfo?.social_media).map((socialMedia, index) => {
                  if (Object.keys(menuInfo?.social_media).length >= 3 && index >= 3) {
                    return (<div key={socialMedia}></div>);
                  }
                  return (
                    <div key={socialMedia}>
                      {

                        menuInfo?.social_media[socialMedia] !== "" ?
                          <div
                            className={'h-[42px] w-[42px] rounded-[42px] image-bg-thirdy text-[#555] flex justify-center items-center mx-2'}>
                            {
                              socialMedia === "facebook" ?
                                <FontAwesomeIcon icon={faFacebook}/>
                                : socialMedia === "instagram" ?
                                  <FontAwesomeIcon icon={faInstagram}/>
                                  : socialMedia === "youtube" ?
                                    <FontAwesomeIcon icon={faYoutube}/>
                                    : socialMedia === "tiktok" ?
                                      <FontAwesomeIcon icon={faTiktok}/>
                                      : socialMedia === "twitter" ?
                                        <FontAwesomeIcon icon={faTwitter}/>
                                        : socialMedia === "foursquare" ?
                                          <FontAwesomeIcon icon={faFoursquare}/>
                                          : <></>
                            }

                          </div>
                          :
                          <></>
                      }
                    </div>
                  )
                })
              ) : (
                <></>
              )

            }
          </div>
        </div>
      </div>

    </div>
  )
}

export default Home;