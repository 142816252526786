import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setAnswersDestroy} from "../../../../storage/feedback";
import StarsRating from "react-star-rate";

const Star = ({id}) => {
    const [val, setVal] = useState(false);
    const {answers} = useSelector(state => state.feedback);
    const dispatch = useDispatch();

    useEffect(() => {
        answers.forEach(answer => {
            if(answer?.qr_menu_feedback_question_id === id){
                let index = answers.indexOf(answer);
                const newArr = Object.assign([...answers, {'qr_menu_feedback_question_id': id, is_required: answer.is_required, answer: val}], answers);
                newArr.splice(index, 1);
                dispatch(setAnswersDestroy(newArr));
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [val])


    return (
        <div className={'rate flex flex-row justify-center'}>
            <StarsRating
                value={val}
                allowHalf={false}
                onChange={value => {
                    setVal(value);
                }}
            />
        </div>
    )
}

export default Star;