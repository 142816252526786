export const ru_translations = {
  translation: {
    menu: {
      go_menu: "Меню",
      select_lang: "Выберите язык",
      suggestions_of_the_moment: "Предложения момента",
      wifi: "Wi-Fi",
      cookie_preferences: "Предпочтения по файлам cookie",
      feedback_form: "Geri Bildirim Formu",
      google_rating: "Bize Google'da Yorum Yapın"
    },
    general: {
      yes: "Да",
      no: "Нет",
      waitstaff_confirm_message: "Вы уверены, что хотите позвать официанта?",
      call_waitstaff: "Позвать официанта",
      calling_waitstaff_successful: "Ваше уведомление было доставлено. ",
      error: "Ошибка!",
      basket: "Корзина",
      my_basket: "Моя корзина",
      basket_text: "Вы можете заказать товары в вашей корзине.",
      no_product: "В вашей корзине нет товаров.",
      order: "Заказать",
      clear_basket: "Очистить корзину",
      add_to_cart: "Добавить в корзину",
      product_detail_sub_title: "Вы можете заказать товары, добавив их в корзину.",
      total: "Итого",
      save: "Сохранить",
      cancel: "Отмена",
      name: "Имя",
      note: "Примечание",
      send: "Отправить",
      required_field: "Поле обязательно к заполнению!",
      successfully_added_to_cart: "Товар успешно добавлен в корзину!",
      successfully_submitted: "Успешно отправлено!",
      please_required_fields: "Пожалуйста, заполните обязательные поля!",
      name_field_cannot_empty: "Поле имени не может быть пустым!",
      determine_the_product_quantity: "Укажите количество товара.",
      maximum_number_of_selections: "Максимальное количество выбора:",
      minimum_number_of_selections: "Минимальное количество выбора для {{item}} — {{quantity}}",
      ssid: "SSID",
      password: "Пароль",
      cookie_title: "Политика файлов cookie",
      cookie_text: "Пожалуйста, укажите свои предпочтения относительно сбора файлов cookie.",
      cookie_info: "Информация",
      cookie_info_text: "Файлы cookie помогают улучшить ваш опыт работы с нашей системой.",
      accept: "Принять",
      reject: "Отклонить",
      your_choice_saved: "Ваш выбор сохранен!",
      ok: "Ок",
      allow_location_access: "Разрешить доступ к местоположению",
      you_can_order_if_you_allow_location_access: "Вы можете сделать заказ, разрешив доступ к местоположению!",
      search_all_products: "Искать все товары...",
      description: "Описание",
      all_items_delete_in_basket: "Все товары в корзине будут удалены!",
      delete: "Удалить",
      successful: "Успешно!",
      delete_the_product: "Вы уверены, что хотите удалить товар?",
      order_failed: "Не удалось сделать заказ!",
      outside_order_border: "Заказ не может быть сделан, так как вы находитесь за пределами зоны доставки!",
      order_transmitted: "Заказ передан!",
      enjoy_your_meal: "Ваш заказ получен. Приятного аппетита!",
      minute: "мин",
      select: "Выбрать",
      required_selections: "Обязательные Выборы",
      optional_selections: "Необязательные Выборы",
    }
  }
}