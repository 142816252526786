import * as React from "react";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import DefaultImg from "../../../../assets/img/default-img.png";
import {TranslatedAttribute} from "../../../../utils";
import {useSelector} from "react-redux";

const Category = ({items}) => {
  const navigate = useNavigate();
  const [, setSubItems] = useState([]);
  const {locale} = useSelector(state => state.app)

  useEffect(() => {
    if (items.length > 0) {
      setSubItems(items[0]?.sub_items)
    }
  }, [items]);

  return (
    <div className={'grid grid-cols-3 mb-16'}>
      {
        items && (
          items.map(item => {
            return (
              <div onClick={() => navigate('/category-detail', {state: {item: item, items: items}})}
                   className={'w-[100px] inline-block mx-4 my-2'} key={item.id}>
                <div
                  className={'w-[100px] h-[100px] rounded-md shadow-lg border-[1px] border-[#000] flex justify-center items-center overflow-hidden'}>
                  <img src={item.image_url || DefaultImg} alt="" className={'w-[100px] h-[100px] object-cover'}/>
                </div>
                <div className={'w-[100px] mt-2 flex justify-center text-[#555] text-[12px] font-semibold text-center'}>
                  {TranslatedAttribute(locale, item, 'name')}
                </div>
              </div>
            )
          })
        )
      }
    </div>
  )
}

export default Category;