import React, {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronUp} from "@fortawesome/free-solid-svg-icons";
import {Collapse} from "@mui/material";

const Collapsible = ({name, children, isOpen = false}) => {
  const [open, setOpen] = useState(isOpen);
  const toggleOpen = () => setOpen(!open);
  const scrollToTarget = (e) => {
    e.parentElement.scrollIntoView({behavior: 'smooth'});
  }
  return (
    <>
      <div className={'w-full'}>
        <div
          className={'text-[#555] font-bold bg-[#f8f8f8] shadow-md py-3 px-2 mb-3 flex flex-row justify-between items-center rounded-xl'}
          onClick={toggleOpen}>
          {name}
          {
            open ?
              <FontAwesomeIcon icon={faChevronUp} color={'#92a09f'}/>
              :
              <FontAwesomeIcon icon={faChevronDown} color={'#92a09f'}/>
          }
        </div>
        <Collapse in={open} onEntered={scrollToTarget} className={'px-2'}>
          {children}
        </Collapse>
      </div>
    </>

  )
}

export default Collapsible;