import {FormControl, MenuItem, Select} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";

const SelectBox = ({
                     selectedOption = "",
                     name = "",
                     onChange = () => {
                     },
                     options
                   }) => {
  const {t} = useTranslation()
  const [selected, setSelected] = useState('')
  const handleChange = (e) => {
    setSelected(e.target.value);
    onChange(e.target)
  }

  useEffect(() => {
    setSelected(selectedOption)
    const event = {
      target: {
        value: selectedOption,
        name: name,
      }
    }
    handleChange(event)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, selectedOption]);

  return (
    <>
      <FormControl size={'small'} className={'w-full'} focused={false}>
        <Select
          id={'selectbox'}
          value={selected}
          className={'w-full mb-2'}
          onChange={handleChange}
          name={name}
          displayEmpty
          classes={{select: '!bg-[#f8f8f8] !w-full !rounded-xl shadow-md'}}
          sx={{fontFamily: 'inherit', boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': {border: 0}}}
          inputProps={{
            sx: {display: 'flex', justifyContent: 'space-between'},
            className: 'bg-blue-900'
          }}
          MenuProps={{
            classes: {paper: '!rounded-xl overflow-y-scroll !max-h-[280px]', list: '!p-0'},
            disableScrollLock: true
          }}
          variant={'outlined'}
        >
          <MenuItem value="" sx={{justifyContent: 'space-between', p: 2, fontFamily: 'inherit'}}>
            <em>{t('general.select')}</em>
            <em></em>
          </MenuItem>
          {
            options.map((option, index) => (
              <MenuItem key={index} value={option.value}
                        sx={{justifyContent: 'space-between', p: 2, fontFamily: 'inherit'}}>
                <div>
                  {option.label}
                </div>
                <div>
                  {option.label_plus}
                </div>
              </MenuItem>
            ))
          }
        </Select>
      </FormControl>
    </>
  )
}

export default SelectBox;
