import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {setAnswersDestroy} from "../../../../storage/feedback";
import sadIcon from "../../../../assets/img/sad_icon.svg";
import neutralIcon from "../../../../assets/img/neutral_icon.svg";
import happyIcon from "../../../../assets/img/happy_icon.svg";

const Emoji = ({id}) => {

    const [val, setVal] = useState(false);
    const {answers} = useSelector(state => state.feedback);
    const dispatch = useDispatch();

    useEffect(() => {

        answers.forEach(answer => {
            if(answer?.qr_menu_feedback_question_id === id){
                let index = answers.indexOf(answer);
                const newArr = Object.assign([...answers, {'qr_menu_feedback_question_id': id, is_required: answer.is_required, answer: val}], answers);
                newArr.splice(index, 1);
                dispatch(setAnswersDestroy(newArr));
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [val])

    let items = [
        {
            id: 1,
            url: sadIcon
        },
        {
            id: 2,
            url: neutralIcon
        },
        {
            id: 3,
            url: happyIcon
        }
    ]

    return (
        <div className={'flex flex-row justify-center'}>

            {
                items.map(item => {
                    return (
                        <input onChange={(e) => setVal(e.target.value)} key={item.id} type="radio" name={`answers[${id}]`} value={item.id}
                               className="neutral grayscale checked:grayscale-0 m-3"
                               style={{
                                   backgroundImage: `url("${item.url}")`,
                                   backgroundSize: 'cover',
                                   width: '45px',
                                   height: '45px',
                                   cursor: 'pointer',
                                   border: 'none',
                                   appearance: 'none'
                               }}
                        />
                    )
                })
            }

        </div>
    )
}

export default Emoji;