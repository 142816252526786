import * as React from "react";

const MainCategory = ({category}) => {
  return (
    <div className={'w-[80px] h-[120px] main-category relative'}>
      <div
        className={'w-100 h-[120px] main-category-image border border-secondary rounded-[12px] overflow-hidden relative shadow-md'}>
        {
          category.image_url && (
            <>
              <img className={'h-full object-cover main-category-image'} alt=""
                   src={category.image_url}/>
              <div className={'h-full w-full bg-black/[0.35] absolute top-0'}/>
            </>
          )
        }
      </div>
      <div className={'main-category-title text-center text-[9pt] text-wrap font-bold ' +
        'uppercase absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'}>
        {category.name}
      </div>
    </div>
  )
}

export default MainCategory