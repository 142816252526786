import {useState} from 'react'
import {setParams} from "../../utils";

const useForm = (initialValues) => {
  const [formData, setFormData] = useState(initialValues);

  const handleInputChange = (name, value, checked = true) => {
    if (checked)
      setParams(formData, name.split('.'), value);
    else
      setParams(formData, name.split('.'));
  };

  return {
    formData,
    handleInputChange,
    setFormData,
  };
};

export default useForm;