import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {setAnswersDestroy} from "../../../../storage/feedback";

const YesNo = ({id}) => {
    const [val, setVal] = useState(false);
    const {answers} = useSelector(state => state.feedback);
    const dispatch = useDispatch();

    useEffect(() => {

        answers.forEach(answer => {
            if(answer?.qr_menu_feedback_question_id === id){
                let index = answers.indexOf(answer);
                const newArr = Object.assign([...answers, {'qr_menu_feedback_question_id': id, is_required: answer.is_required, answer: val}], answers);
                newArr.splice(index, 1);
                dispatch(setAnswersDestroy(newArr));
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [val])

    return (
        <div className={'yes_no w-full flex flex-row justify-center'}>
            <div className={'my-6'}>
                <input onChange={(e) => setVal(e.target.value)} type="radio" name={`answers[${id}]`} id="yes" value="1" className={'appearance-none'}/>
                <label htmlFor="yes" className={'mx-2 py-4 px-5 border-2 border-gray-500'}>Evet</label>
            </div>
            <div className={'my-6'}>
                <input onChange={(e) => setVal(e.target.value)} type="radio" name={`answers[${id}]`} id="no" value="2" className={'appearance-none'}/>
                <label htmlFor="no" className={'mx-2 py-4 px-5 border-2 border-gray-500'}>Hayır</label>
            </div>
        </div>
    )
}

export default YesNo;