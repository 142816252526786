import Locale from "../HeaderButton";
import HomeButton from "../Button/HomeButton";

const Header = ({isShowHomeButton = false}) => {
  return (
    <div
      className={'w-full h-[70px] flex flex-row justify-between items-center px-2 bg-[#fff] absolute top-0 mb-[75px] bg-transparent'}>
      <div className={''}>
        {isShowHomeButton === true ? <HomeButton/> : <></>}
      </div>
      <div className={'p-2 flex flex-row justify-end'}>
        <Locale/>
      </div>
    </div>
  )
}

export default Header;