import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
const MainScreen = () => {
    const navigate = useNavigate();
    useEffect(() => {
        if (window.location.host === 'zeusbeachdidim.com') {
            navigate(`/zeus-beach-club/menu`);
        }
    }, [navigate])

    return null
}

export default MainScreen;