import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {Button} from "@mui/material";
import {useTranslation} from "react-i18next";

const ConfirmDialog = ({open, onClose, message, onApprove}) => {
  const {t} = useTranslation();
  const handleClose = (val) => {
    if (val) {
      onApprove()
    }
    onClose()
  }
  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogContent>
          {message}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)}>{t('general.no')}</Button>
          <Button onClick={() => handleClose(true)}>{t('general.yes')}</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
export default ConfirmDialog;