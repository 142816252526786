import {Outlet} from "react-router-dom";
import {useEffect} from "react";
import {useSelector} from "react-redux";

const Theme4Layout = () => {
  const {themeOption} = useSelector(state => state.app);

  useEffect(() => {
    import(`./assets/css/${themeOption}.css`)
  }, [themeOption]);

  return (
    <div
      className={'max-w-[430px] mx-auto h-[100dvh] overflow-y-scroll no-scrollbar relative bg-primary text-primary'}
      style={{fontFamily: "'Lato', sans-serif"}}>
      <Outlet/> {/* Routes will be rendered here */}
    </div>
  )
}

export default Theme4Layout;