import {useLocation, useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons";
import {useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import BasketButton from "../../components/BasketButton";
import Fuse from 'fuse.js'
import Locale from "../../../Theme3/components/HeaderButton";
import {useTranslation} from "react-i18next";
import Loader from "../../components/Loader";
import {TranslatedAttribute} from "../../../../utils";
import WaiterCallButton from "../../../../components/WaiterCallButton";


const CategoryDetail = () => {
  const {isOrderAvailable, locale, tableId, menuInfo} = useSelector(state => state.app)
  const navigate = useNavigate();
  const {state} = useLocation();
  const {item, items} = state;
  const {currencySymbol} = useSelector(state => state.app);
  const [activeClass, setActiveClass] = useState('');
  const [allProducts] = useState([]);
  const [searchProducts, setSearchProducts] = useState([]);
  const {t} = useTranslation();
  const {loading} = useSelector(state => state.loader)

  const checkActiveClass = (status, data) => {
    setActiveClass(status);
    navigate('/category-detail', {state: {item: data, items: items}})
  }

  const TopCategory = ({data}) => {
    return (
      <div onClick={() => checkActiveClass(data.id, data)}
           className={`px-1 py-2 inline-block m-3 ${activeClass === data.id ? 'bg-[#fff] text-[#000] rounded-md' : 'text-[#fff]'}`}>
        {TranslatedAttribute(locale, data, 'name')}
      </div>
    )
  }

  useEffect(() => {
    items.forEach((dataItem) => {
      if (Boolean(dataItem?.sub_items)) {
        dataItem?.sub_items.forEach((subDataItem) => {
          allProducts.push(subDataItem);
        })
      }
    })
    // eslint-disable-next-line
  }, [])

  const fuseOptions = {
    keys: [
      "name"
    ],
    threshold: 0.4,
  };

  const searchData = (searchData) => {

    if (Boolean(searchData)) {
      if (searchData.length >= 3) {
        const fuse = new Fuse(allProducts, fuseOptions);
        setSearchProducts(fuse.search(searchData))
      }
    } else {
      setSearchProducts([])
    }
  }

  const GetSubItems = ({subItem}) => {
    let resourceType = subItem.resource_type;
    return (
      //<div key={subItem.id} onClick={() => resourceType === 'ProductCategory' ? {} : navigate('/product-detail', { state: { item: subItem }})}
      <div key={subItem.id}
           onClick={() => resourceType === 'ProductCategory' ? {} : navigate('/product-detail', {state: {item: subItem}})}
           className={resourceType === 'Product' ? `bg-[#fff] flex flex-row justify-between items-center border-[1px] border-gray-400 m-3` : `flex flex-row justify-between items-center sticky min-h-[55px] top-[125px] z-0 bg-[#fff] text-[#000] shadow-xl border-[1px] border-gray-400 m-3`}>
        <div className={'ml-3 basis-auto'}>
          <p className={'font-semibold'}>{TranslatedAttribute(locale, subItem, 'name')}</p>
          <p className={'font-normal line-clamp-2 text-xs'}>{TranslatedAttribute(locale, subItem, 'description')}</p>
          {
            resourceType === 'ProductCategory' ? (
              <></>
            ) : (
              <p>{(subItem.price[currencySymbol].toString().includes('-') ? subItem.price[currencySymbol] : `${parseFloat(subItem.price[currencySymbol]).toFixed(2)} ${currencySymbol}`)}</p>
            )
          }
        </div>
        <div className={'flex justify-center items-center overflow-hidden min-w-[120px] justify-self-end basis-auto'}>
          {
            resourceType === 'ProductCategory' ? (
              <></>
            ) : (
              <img src={subItem.image_url} alt="" className={' w-[120px] object-cover'}/>
            )
          }
        </div>
      </div>
    )
  }


  return (
    <div className={'max-w-[420px] mx-auto h-full pt-[125px] pb-[75px] bg-[#f3f4f6]'}>
      <div className={'w-full max-w-[420px] fixed bg-[#000] top-0 z-10'}>

        <div className={'py-2 px-3'}>
          <div className={'flex flex-row items-center'}>
            <div onClick={() => navigate('/menu')}>
              <FontAwesomeIcon icon={faChevronLeft} color={'#fff'} className={'font-bold m-1 w-[20px] h-[20px]'}/>
            </div>

            <div className={'w-full overflow-x-auto whitespace-nowrap no-scrollbar mx-1'}>
              {
                items.map(data => {
                  return (<TopCategory key={data.id} data={data}/>)
                })
              }
            </div>

            <Locale/>
          </div>

          <div className={'mt-1 relative'}>
            <input type="text" onKeyUp={(e) => searchData(e.target.value)}
                   placeholder={t('general.search_all_products')}
                   className={'h-[40px] w-full rounded-md bg-[#333] focus:outline-0 text-[#9ca3af] px-2'}/>
            <div className={'max-h-[300px] w-full bg-[#333] text-[#fff] overflow-auto absolute top-[45px] left-0'}>
              {
                searchProducts.length > 0 && (
                  searchProducts.map(search => {
                    return (
                      <div key={search.item.id} className={'border-b-[1px] border-black p-2'}
                           onClick={() => navigate('/product-detail', {state: {item: search.item}})}>
                        {TranslatedAttribute(locale, search.item, 'name')}
                      </div>)
                  })
                )

              }
            </div>
          </div>


        </div>
      </div>

      <div className={'flex flex-col justify-center bg-[#f3f4f6] pb-[100px]'}>
        {
          (item.sub_items || []).map(subItem => {
            return (
              <div key={`wrapper-${subItem.id}`}>
                <GetSubItems subItem={subItem}/>
                {
                  subItem.resource_type === 'ProductCategory' && subItem?.sub_items ? (
                    subItem.sub_items.map(sItem => {
                      return (<GetSubItems key={`${subItem.id}-${sItem.id}`} subItem={sItem}/>)
                    })
                  ) : (<></>)
                }
              </div>
            )
          })
        }
        {
          tableId && menuInfo.can_call_waitstaff &&
          <WaiterCallButton/>
        }
        {
          tableId && menuInfo.can_call_waitstaff &&
          <div className={'pt-[15px]'}/>
        }
      </div>

      {
        isOrderAvailable === true ? <BasketButton/> : <></>
      }
      {
        loading ? <Loader/> : null
      }
    </div>

  )
}

export default CategoryDetail;