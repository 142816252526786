import * as React from "react";
import {useEffect, useState} from "react";
import CollapseHead from "../../components/Category/CollapseHead";
import {TranslatedAttribute} from "../../../../utils";
import {useSelector} from "react-redux";

const Category = ({items}) => {

  const {locale} = useSelector(state => state.app);
  const [subItems, setSubItems] = useState([]);

  useEffect(() => {
    if (items?.length > 0) {
      setSubItems(items[0]?.sub_items)
    }
  }, [items]);

  const changeScroll = (id) => {
    var el = document.getElementById(id);
    el.scrollIntoView({behavior: 'smooth'}, true);
  }

  return (
    <div>
      <div className={'w-full sticky top-0 overflow-x-auto whitespace-nowrap no-scrollbar bg-[#fff] z-[999]'}>
        <div className={'flex flex-row justify-between'}>
          {
            items && (
              items.map(item => {
                return (
                  <div onClick={() => changeScroll(`${item.id}`)} className={'inline-block m-4'} key={item.id}>
                    <div className={'mt-2 flex justify-center text-[#555] text-[18px] font-semibold'}>
                      <span
                        className={'border-b-2 border-blue-950 px-3 py-1 rounded-2xl'}>{TranslatedAttribute(locale, item, 'name')}</span>
                    </div>
                  </div>
                )
              })
            )
          }
        </div>
      </div>


      <div className={'flex flex-col'}>
        {
          items && (
            items.map(item => {
              return (
                <div className={'w-[95%] inline-block my-4 mx-auto'} key={item.id}>

                  <div className={'w-full block text-center font-bold text-xl italian-menu-title'}>
                    <span id={item.id}>{TranslatedAttribute(locale, item, 'name')}</span>
                  </div>

                  <div>
                    {
                      item?.sub_items?.length > 0 ?
                        (
                          item.sub_items.map(item => {
                            return (
                              <div key={item.id} className={'m-1'}>
                                <CollapseHead key={item.id} item={item} extraStyle={'bg-[#fafafa]'}/>
                              </div>
                            )
                          })
                        ) : (
                          <></>
                        )
                    }
                  </div>
                </div>
              )
            })
          )
        }
      </div>


      <div className={'hidden'}>

        {
          subItems && subItems?.length > 0 ?
            (
              subItems.map(item => {
                return (
                  <div key={item.id} className={'m-1'}>
                    <CollapseHead key={item.id} item={item} extraStyle={'bg-[#fafafa]'}/>
                  </div>
                )
              })
            ) : (
              <></>
            )
        }

      </div>
    </div>
  )
}

export default Category;