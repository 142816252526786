import * as React from "react";
import {useSelector} from "react-redux";
import {createModal} from "../../utils/modal";
import {TranslatedAttribute} from "../../../../utils";

const CollapseHead = ({item, extraStyle = 'bg-[#d3ece9]'}) => {

  const {locale} = useSelector(state => state.app);
  const {currencySymbol} = useSelector(state => state.app);
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <div className={'w-full image-bg-thirdy mb-4 mx-auto shadow-lg'}>
      <div className={'p-[15px] flex justify-between items-center'} onClick={handleClick}>
        <div key={item.id}
             className={`relative w-full font-bold text-[#555] flex flex-row justify-between items-center grid grid-cols-12 gap-4`}
             onClick={() => {
               if ((item.resource_type === 'Product' || (item.resource_type === null && item.item_type === 'product')) && (item.sub_items?.length || 0) === 0) {
                 createModal('italian_product_detail', item)
               }
             }}>
          <div className={'col-span-9'}>
            <span className={'text-lg'}>{TranslatedAttribute(locale, item, 'name')}</span>
            <p className={'text-[18px] font-normal line-clamp-2'}>{TranslatedAttribute(locale, item, 'description')}</p>
          </div>
          <div className={'col-span-3 text-right'}>
            {
              (item.resource_type === 'Product' || (item.resource_type === null && item.item_type === 'product')) && (item.sub_items?.length || 0) === 0 ? (item.price[currencySymbol].toString().includes('-') ? item.price[currencySymbol] : `${parseFloat(item.price[currencySymbol]).toFixed(2)} ${currencySymbol}`) : ''
            }
          </div>
        </div>
      </div>
      {
        item.sub_items && (item?.sub_items?.length || 0) > 0 ? (
          <div className={`p-3 flex justify-between rounded-[25px] m-1 ${extraStyle}`}>
            <div className={'w-full flex flex-col'}>
              {
                item.sub_items && (item?.sub_items?.length || 0) > 0 ? (
                  item.sub_items.map(subItem => {
                      window.last = subItem
                      return subItem.sub_items && (subItem?.sub_items?.length || 0) > 0 ?
                        <CollapseHead key={subItem.id} item={subItem} extraStyle={'bg-[#FAF9F7FF] text-sm'}/>
                        :
                        <div key={subItem.id}
                             className={`relative grid grid-cols-12 gap-4 font-bold text-sm text-[#555] rounded-[25px] p-2 block flex flex-row justify-between ${extraStyle}`}
                             onClick={() => {
                               createModal('italian_product_detail', subItem)
                             }}>
                          {
                            subItem?.is_available ? (
                              <></>
                            ) : (
                              <div className={'absolute w-full h-full grid place-content-center'}>
                                <div
                                  className={'text-center bg-red-400 rounded-xl px-3 py-1 text-[#fff] my-5 bg-opacity-80'}>
                                  Servis Kapalı
                                  <span className={'block text-xs'}>{`${subItem.start_hour} - ${subItem.end_hour}`}</span>
                                </div>
                              </div>
                            )
                          }

                          <div className={'col-span-9'}>
                            <span className={'text-xl'}>{TranslatedAttribute(locale, subItem, 'name')}</span>
                            <p
                              className={'text-[18px] font-normal line-clamp-2'}>{TranslatedAttribute(locale, subItem, 'description')}</p>
                          </div>
                          <div
                            className={'col-span-3 text-right text-[17px]'}>{(subItem.price[currencySymbol].toString().includes('-') ? subItem.price[currencySymbol] : `${parseFloat(subItem.price[currencySymbol]).toFixed(2)} ${currencySymbol}`)}</div>
                        </div>
                    }
                  )
                ) : (<></>)
              }
            </div>
          </div>
        ) : (<></>)
      }

    </div>
  )
}

export default CollapseHead;