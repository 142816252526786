export const en_translations = {
  translation: {
    menu: {
      go_menu: 'Go To Menu',
      select_lang: 'Select Language',
      suggestions_of_the_moment: 'Suggestions of the Moment',
      wifi: 'Wi-Fi',
      cookie_preferences: 'Cookies Preferences',
      feedback_form: 'Give us a feedback',
      google_rating: "Rate us on Google"
    },
    general: {
      yes: "Yes",
      no: "No",
      waitstaff_confirm_message: "Are you sure you want to call the waitstaff?",
      call_waitstaff: "Call Waitstaff",
      calling_waitstaff_successful: "Your notification has been delivered.",
      error: "Error!",
      basket: "Basket",
      my_basket: "My Basket",
      basket_text: "You can order the products in your cart.",
      no_product: "There is no product in your cart.",
      order: "Order",
      clear_basket: "Clear Basket",
      add_to_cart: "Add to Cart",
      product_detail_sub_title: "You can place an order by adding the products to your cart..",
      total: "Total",
      save: "Save",
      cancel: "Cancel",
      name: "Name",
      note: "Note",
      send: "Send",
      required_field: "Field is Mandatory Selection!",
      successfully_added_to_cart: "The product has been successfully added to your cart!",
      successfully_submitted: "Successfully Submitted!",
      please_required_fields: "Please Fill In The Mandatory Fields!",
      name_field_cannot_empty: "Name Field Cannot Be Empty!",
      determine_the_product_quantity: "Determine the Product Quantity.",
      maximum_number_of_selections: "Maximum Number of Selections:",
      minimum_number_of_selections: "Minimum number of selections for {{item}} is {{quantity}}",
      ssid: "Wifi Name",
      password: "Password",
      cookie_title: "Cookie Policy",
      cookie_text: "Please indicate your preference regarding the collection of cookies.",
      cookie_info: "To inform",
      cookie_info_text: "Cookies improve your experience on our system.",
      accept: "Accept",
      reject: "Reject",
      your_choice_saved: "Your Choice Saved!",
      ok: 'OK',
      allow_location_access: 'Allow Location Access',
      you_can_order_if_you_allow_location_access: 'You can order if you allow location access!',
      search_all_products: 'Search All Products...',
      description: 'Description',
      all_items_delete_in_basket: 'All Items In The Basket Are About To Be Deleted!',
      delete: 'Delete',
      successful: 'Successful!',
      delete_the_product: 'You are about to delete the product!',
      order_failed: 'Order Failed!',
      outside_order_border: 'The order could not be placed because you are outside the ordering border!',
      order_transmitted: 'Order transmitted!',
      enjoy_your_meal: 'Your order has reached us. Enjoy your meal!',
      minute: 'Min',
      select: 'Select',
      required_selections: "Required Selections",
      optional_selections: "Optional Selections",
    }
  }
}