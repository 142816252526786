import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    modal: {}
}

const drawermenu = createSlice({
    name: 'drawermenu',
    initialState,
    reducers: {
        append: (state, action) => {
            state.modal = action.payload;
        },
        destroy: (state, action) => {
            state.modal = [];
            /*const data = [...state.modals]
            data.pop();
            state.modals = data;*/
        },
        destroyAll: (state) => {
            state.modal = [];
        }
    }
})

export const { append, destroy, destroyAll } = drawermenu.actions
export default drawermenu.reducer