import React, {useEffect, useState} from "react";
import {faCircleMinus, faCirclePlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Counter = ({
                   label = '',
                   name,
                   onChange = () => {
                   },
                   whenZero = () => {
                   },
                   min = 0,
                   buttonClassNames = 'm-1',
                   iconClassNames = 'image-text-primary',
                   iconDisabledClassNames = 'image-text-thirdy',
                   counterClassNames = 'flex justify-center items-center shadow-md text-[#555555] font-bold bg-[#f8f8f8] m-1 w-[40px] h-[40px] rounded-[30px]'
                 }) => {

  const [count, setCount] = useState(1)
  const handleChange = (val) => {
    const new_val = count + val
    setCount(new_val)
    const e = {name: name, value: new_val}
    onChange(e)
  }

  useEffect(() => {
    const e = {name: name, value: 1}
    onChange(e)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  return (
    <>
      <div className={'flex justify-end'}>
        <label className={'flex'}>
          {label}
          <br/>
          <button disabled={count === min} className={buttonClassNames} onClick={() => handleChange(-1)}>
            <FontAwesomeIcon icon={faCircleMinus}
                             className={count === min ? iconDisabledClassNames : iconClassNames}
                             size={'xl'}/>
          </button>
          <div
            className={counterClassNames}>
            {count}
          </div>
          <button className={buttonClassNames} onClick={() => handleChange(1)}>
            <FontAwesomeIcon icon={faCirclePlus}
                             className={iconClassNames} size={'xl'}/>
          </button>
        </label>
      </div>
    </>
  )
}

export default Counter;