import React, {useEffect, useState} from "react";
import {useGetProductQuery} from "../../../../storage/api";
import CheckBox from "../../../../components/form/CheckBox";
import Counter from "../../../../components/form/Counter";
import toast from "react-hot-toast";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {addToCartV2, TranslatedAttribute} from "../../../../utils";
import {destroyModal} from "../../utils/modal";
import Collapsible from "../../../../components/muiComponents/Collapsible";
import useForm from "../../../../components/form";
import Variant from "../../../Theme4/components/Products/Variant";

const ProductForm = ({item}) => {
  const {formData, handleInputChange, setFormData} = useForm()
  const [totalPrice, setTotalPrice] = useState(0)
  const {data} = useGetProductQuery({id: item.id});
  const {currencySymbol, basket, locale} = useSelector(state => state.app);
  const {t} = useTranslation()
  const [variantsObject, setVariantsObject] = useState()

  const minValidation = () => {
    let flag = true
    Object.entries(formData.variants).forEach(([key, value]) => {
      if (Object.keys(value).length < variantsObject[key].min) {
        flag = false
        toast.error(t('general.minimum_number_of_selections', {
          item: TranslatedAttribute(locale, variantsObject[key], 'name'),
          quantity: variantsObject[key].min
        }));
      }
    })
    return flag
  }

  const handleChange = (e) => {
    let val
    try {
      val = JSON.parse(e.value)
    } catch (e) {
      val = e.value
    } finally {
      handleInputChange(e.name, val, e.checked)
    }
    calc_total_price()
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const calc_total_price = () => {
    let sum_price = 0
    Object.entries(Object.values(formData.variants)).forEach(([_, values]) => {
      Object.values(values).forEach((value) => {
        sum_price += parseFloat(value.additional_price);
      })
    })
    Object.entries(Object.values(formData.options)).forEach(([_, value]) => {
      sum_price += (parseFloat(value.price) || 0) * (value.amount || 1);
    })
    const price = (((data.price || 0) + sum_price) * formData.amount)
    formData['total_price'] = price;
    setTotalPrice(price);
  }

  useEffect(() => {
    if (data) {
      let variantObj = {}
      data.variants.forEach((variant) => {
        variantObj[variant.id] = variant
      })
      setVariantsObject(variantObj)
    }
  }, [data])

  useEffect(() => {
    data && variantsObject && setFormData({
      "identifier": '0',//new Date().getTime(),
      "id": data.id,
      "name": TranslatedAttribute(locale, data, 'name'),
      "price": data.price[currencySymbol],
      "amount": 1,
      "total_price": item.price[currencySymbol],
      "unit_price": 0,
      "variants": (() => {
        let variants = {}
        Object.entries(variantsObject).forEach(([key, _]) => {
          variants[key] = {}
        })
        return variants
      })(),
      "options": {}
    })
  }, [data, locale, currencySymbol, item.price, variantsObject, setFormData]);

  useEffect(() => {
    formData && calc_total_price()
  }, [calc_total_price, formData])


  const SubmitForm = ({price}) => {
    return (
      <>
        <div className={'flex justify-center flex-col sticky bottom-0 left-0 right-0 w-full bg-white'}>
          <div className={'w-full text-right flex justify-end items-end mt-[15px]'}>
            <div className={'text-[21px]'}>
              <span
                className={'font-bold text-[#555555]'}>{t('general.total')}</span> {`${price?.toFixed(2) + ' ' + currencySymbol}`}
            </div>
          </div>
          <div className={'flex justify-center'}>
            <button
              onClick={() => formData?.amount > 0 ? addToCartHandle() : toast.error(t('general.determine_the_product_quantity'))}
              className={'w-1/2 rounded-3xl text-[#fff] px-5 py-3 font-bold mt-2 ' + (formData?.amount === 0 ? 'image-bg-thirdy' : 'bg-[#00796b]')}>
              {t('general.add_to_cart')} ({basket.items.length})
            </button>
          </div>
        </div>
      </>
    )
  }

  // Variantlar min: 0, max: 1 ise doldurulmak zorunda degiller bu yuzden yorum satiri ilerde kullanilabilir.
  const checkRequiredFields = () => {
    // return Object.entries(formData.variants).every(([_, value]) => {
    //   return Object.keys(value).length !== 0;
    // });
    return true
  }

  const addToCartHandle = () => {
    if (minValidation() && checkRequiredFields()) {
      addToCartV2(formData)
      destroyModal()
    }
  }

  if (formData)
    return (
      <>
        <div className={'flex justify-between w-full'}>
          <div className={'text-[20pt]'}>
            {(`${(item.price[currencySymbol]).toFixed(2) + ' ' + currencySymbol}`)}
          </div>
          <div className={'flex flex-row items-center justify-between'}>
            <Counter name={'amount'}
                     onChange={handleChange} min={1}
                     iconClassNames={'text-[#00796b]'}/>
          </div>
        </div>
        {data && (
          <>
            {
              data.variants && data.variants.length > 0 && (
                <>
                  <h4 className={'text-left my-2 w-full'}>{t('general.required_selections')}</h4>
                  {
                    data.variants.map(variant => {
                      return (
                        <Collapsible key={variant.id} name={TranslatedAttribute(locale, variant, 'name')}>
                          <Variant variant={variant} onChange={handleChange} max={variant.max}/>
                        </Collapsible>
                      )
                    })
                  }
                </>
              )
            }
            {
              data.options && Object.keys(data.options).length > 0 && (
                <>
                  <h4 className={'text-left my-2 w-full'}>{t('general.optional_selections')}</h4>
                  {
                    Object.entries(data.options).map(([name, options]) => {
                      return (
                        <Collapsible key={`option-group.${name}`} name={name}>
                          {
                            options.map(option => {
                              return (
                                <CheckBox name={`options.${option.id}`} value={JSON.stringify(option)}
                                          onChange={handleChange} label={option.name} key={`options.${option.id}`}
                                          label_plus={`+${option.price || 0} ${currencySymbol}`}>
                                  <Counter name={`options.${option.id}.amount`}
                                           onChange={handleChange} key={`options.${option.id}.amount`}
                                           iconClassNames={'text-[#00796b]'}/>
                                </CheckBox>
                              )
                            })
                          }
                        </Collapsible>
                      )
                    })
                  }
                </>
              )
            }
          </>
        )}
        <SubmitForm price={totalPrice}/>
      </>
    )
}
export default ProductForm