import * as React from "react";
import {useEffect, useState} from "react";
import CollapseHead from "../../../ImageDefault/components/Category/CollapseHead";
import {useSelector} from "react-redux";
import {TranslatedAttribute} from "../../../../utils";

const Category = ({items}) => {
    const {locale} = useSelector(state => state.app)
    const [subItems, setSubItems] = useState([]);

    useEffect(() => {
        if(items?.length > 0){
            setSubItems(items[0]?.sub_items)
        }
    }, [items]);

    return (
        <div>
            <div className={'w-full relative overflow-x-auto whitespace-nowrap no-scrollbar'}>
                {
                    items && (
                        items.map(item => {
                            return (
                                <div onClick={() => setSubItems(item.sub_items)} className={'w-[100px] inline-block m-4'} key={item.id}>
                                    <div className={'w-[100px] h-[100px] rounded-full shadow-lg border-[#7e0d0d] border-2 flex justify-center items-center overflow-hidden'}>
                                        <img src={item.image_url} alt="" className={'w-[100px] h-[100px]'}/>
                                    </div>
                                    <div className={'w-[100px] mt-2 flex justify-center text-[#555] text-[12px] font-semibold text-wrap text-center'}>
                                        { TranslatedAttribute(locale, item, 'name') }
                                    </div>
                                </div>
                            )
                        })
                    )
                }
            </div>

            <div>

                {
                    subItems && subItems?.length > 0 ?
                        (
                            subItems.map( item => {
                                return (
                                    <div key={item.id} className={'m-1'}>
                                        <CollapseHead key={item.id} item={item} extraStyle={'bg-[#fafafa]'} />
                                    </div>
                                )
                            })
                        ):(
                            <></>
                        )
                }

            </div>
        </div>
    )
}

export default Category;