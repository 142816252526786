import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import * as React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronUp} from "@fortawesome/free-solid-svg-icons";
import {useSelector} from "react-redux";
import {createModal} from "../../utils/modal";
import {TranslatedAttribute} from "../../../../utils";

const CollapseHead = ({item, extraStyle = 'bg-[#d3ece9]'}) => {

  const {currencySymbol, locale} = useSelector(state => state.app);
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <div className={`w-full bg-[#d3ece9] rounded-[25px] mb-3 mx-auto`}>
      <div className={'p-[15px] flex justify-between items-center'} onClick={handleClick}>
        <div key={item.id}
             className={`w-full font-bold text-[#555] flex flex-row justify-between items-center grid grid-cols-12 gap-4`}
             onClick={() => {
               if (item.resource_type === 'Product' && (item.sub_items?.length || 0) === 0) {
                 createModal('no_image_product_detail', item)
               }
             }}>
          <div className={'col-span-8'}>
            <span>{TranslatedAttribute(locale, item, 'name')}</span>
            <p className={'text-[12px] font-normal line-clamp-2'}>{TranslatedAttribute(locale, item, 'description')}</p>
          </div>
          <div className={'col-span-4 text-right'}>
            {
              (item.resource_type === 'Product' || (item.resource_type === null && item.item_type === 'product')) && (item.sub_items?.length || 0) === 0 ? (item.price[currencySymbol].toString().includes('-') ? item.price[currencySymbol] : `${parseFloat(item.price[currencySymbol]).toFixed(2)} ${currencySymbol}`) : ''
            }
          </div>
        </div>
        <div className={'ml-3'}>
          {
            item.sub_items && (item.sub_items?.length || 0) > 0 ? (
              open ?
                <FontAwesomeIcon icon={faChevronUp} color={'#92a09f'}/>
                :
                <FontAwesomeIcon icon={faChevronDown} color={'#92a09f'}/>
            ) : (<></>)
          }
        </div>
      </div>
      {
        item.sub_items && (item?.sub_items?.length || 0) > 0 ? (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <div className={`p-3 flex justify-between rounded-[25px] m-1 ${extraStyle}`}>
                <div className={'w-full flex flex-col'}>
                  {
                    item.sub_items && (item?.sub_items?.length || 0) > 0 ? (
                      item.sub_items.map(subItem => {
                          return subItem.sub_items && (subItem?.sub_items?.length || 0) > 0 ?
                            <CollapseHead key={subItem.id} item={subItem} extraStyle={'bg-[#f2f9f8] text-sm'}/>
                            :
                            <div key={subItem.id}
                                 className={`relative grid grid-cols-12 gap-4 font-bold text-sm text-[#555] rounded-[25px] p-2 block flex flex-row justify-between ${extraStyle}`}
                                 onClick={() => {
                                   createModal('no_image_product_detail', subItem)
                                 }}>
                              {
                                subItem?.is_available ? (
                                  <></>
                                ) : (
                                  <div className={'absolute w-full h-full grid place-content-center'}>
                                    <div
                                      className={'text-center bg-red-400 rounded-xl px-3 py-1 text-[#fff] my-5 bg-opacity-80'}>
                                      Servis Kapalı
                                      <span
                                        className={'block text-xs'}>{`${subItem.start_hour} - ${subItem.end_hour}`}</span>
                                    </div>
                                  </div>
                                )
                              }
                              <div className={'col-span-8'}>
                                <span>{TranslatedAttribute(locale, subItem, 'name')}</span>
                                <p
                                  className={'text-[12px] font-normal line-clamp-2'}>{TranslatedAttribute(locale, subItem, 'description')}</p>
                              </div>
                              <div
                                className={'col-span-4 text-right'}>{(subItem.price[currencySymbol].toString().includes('-') ? subItem.price[currencySymbol] : `${parseFloat(subItem.price[currencySymbol]).toFixed(2)} ${currencySymbol}`)}</div>
                            </div>
                        }
                      )
                    ) : (<></>)
                  }
                </div>
              </div>
            </List>
          </Collapse>
        ) : (<></>)
      }

    </div>
  )
}

export default CollapseHead;