import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false
}

const loader = createSlice({
    name: 'loader',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload
        },
    }
})

export const { setLoading } = loader.actions
export default loader.reducer