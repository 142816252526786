import {useSelector} from "react-redux";
import {Route, Routes, useLocation} from "react-router-dom";
import Elements from "../Elements";
import {AnimatePresence} from "framer-motion";

const ThemeRoute = () => {
  const {theme} = useSelector(state => state.app)

  const routes = {
    "no_image_default": {
      "alis": "no_image_default",
      "layout": "NoImageDefaultLayout",
      "routes": [
        {
          "id": 2,
          "path": "/home",
          "element": "NoImageDefaultHome"
        },
        {
          "id": 3,
          "path": "/menu",
          "element": "NoImageDefaultMenu"
        },
        {
          "id": 4,
          "path": "/feedback",
          "element": "NoImageDefaultFeedbackForm"
        },
        {
          "id": 5,
          "path": "/",
          "element": "MainScreen"
        }
      ]
    },
    "image_default": {
      "alis": "image_default",
      "layout": "ImageDefaultLayout",
      "routes": [
        {
          "id": 2,
          "path": "/home",
          "element": "ImageDefaultHome"
        },
        {
          "id": 3,
          "path": "/menu",
          "element": "ImageDefaultMenu"
        },
        {
          "id": 4,
          "path": "/feedback",
          "element": "ImageDefaultFeedbackForm"
        },
        {
          "id": 5,
          "path": "/",
          "element": "MainScreen"
        }
      ]
    },
    "theme_3": {
      "alis": "theme_3",
      "layout": "Theme3Layout",
      "routes": [
        {
          "id": 2,
          "path": "/home",
          "element": "Theme3Home"
        },
        {
          "id": 3,
          "path": "/menu",
          "element": "Theme3Menu"
        },
        {
          "id": 4,
          "path": "/category-detail",
          "element": "Theme3CategoryDetail"
        },
        {
          "id": 5,
          "path": "/product-detail",
          "element": "Theme3ProductDetail"
        },
        {
          "id": 5,
          "path": "/",
          "element": "MainScreen"
        }
      ]
    },
    "italian": {
      "alis": "italian",
      "layout": "ItalianLayout",
      "routes": [
        {
          "id": 2,
          "path": "/home",
          "element": "ItalianHome"
        },
        {
          "id": 3,
          "path": "/menu",
          "element": "ItalianMenu"
        },
        {
          "id": 4,
          "path": "/category-detail",
          "element": "Theme3CategoryDetail"
        },
        {
          "id": 5,
          "path": "/product-detail",
          "element": "Theme3ProductDetail"
        },
        {
          "id": 5,
          "path": "/",
          "element": "MainScreen"
        }
      ]
    },
    "theme_4": {
      "alis": "theme_4",
      "layout": "Theme4Layout",
      "routes": [
        {
          "id": 2,
          "path": "/home",
          "element": "Theme4Home"
        },
        {
          "id": 3,
          "path": "/menu",
          "element": "Theme4Menu"
        },
        {
          "id": 4,
          "path": "/feedback",
          "element": "Theme4FeedbackForm"
        }
      ]
    },
  };

  const currentRoutes = routes[theme] || routes["no_image_default"];
  const location = useLocation()

  return (

    <AnimatePresence mode={'wait'}>
      <Routes location={location} key={location.key}>
        <Route element={Elements(currentRoutes.layout)}>
          {
            currentRoutes.routes.map((item) =>
              <Route key={item.id} path={item.path} element={Elements(item.element)}/>
            )
          }
        </Route>
        <Route key={'1'} path={'/:restaurantId/menu'} element={Elements('SplashScreen')}/>
      </Routes>
    </AnimatePresence>

  )
}

export default ThemeRoute;