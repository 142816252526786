import Locale from "../HeaderButton";
import HomeButton from "../Button/HomeButton";

const Header = ({isShowHomeButton = false}) => {
    return (
        <div className={'w-full h-[70px] flex flex-row justify-between items-center px-2'}>
            <div>
                { isShowHomeButton === true ? <HomeButton /> : <></>}
            </div>
            <div className={'p-2 flex flex-row justify-end'}>
                <Locale />
            </div>
        </div>
    )
}

export default Header;