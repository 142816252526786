import Product from "./Product";
import {createModal} from "../../utils/modal";
import * as React from "react";
import {useState} from "react";
import Collapse from "@mui/material/Collapse";

const ProductList = ({item}) => {
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);
  return (
    <div className={'bg-secondary rounded-[12px] shadow-md'} key={'product-' + item.id}>
      <div
        className={'h-[110px] rounded-[12px] bg-secondary flex text-black' + (open ? ' rounded-b-none' : '')}
        onClick={() => {
          toggle();
        }}>
        <Product item={item} key={'product-' + item.id}/>
      </div>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <>
          {
            item.sub_items.map(item => {
              if (item.sub_items) {
                return (
                  <div key={item.id} className={'my-2 mx-1 shadow-[0_5px_15px_0px_rgba(0,0,0,0.3)] rounded-xl'}>
                    <ProductList item={item}/>
                  </div>
                )
              } else
                return (
                  <div key={item.id}
                       className={'h-[75px] rounded-[12px] bg-[#F4ECE1] flex text-black mb-[5px] mx-[5px] items-center shadow-md'}
                       onClick={() => {
                         createModal('product_detail', item)
                       }}
                       key={'sub-product-' + item.id}>
                    <Product item={item} sub_product={true}/>
                  </div>
                )
            })
          }
        </>
      </Collapse>
    </div>
  )
}

export default ProductList;