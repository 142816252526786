import HomeButton from "./HomeButton";
import Locale from "../HeaderButton";
import {useSelector} from "react-redux";
import {TranslatedAttribute} from "../../../../utils";
import React from "react";

const Header = ({isShowHomeButton = false}) => {
    const {menuInfo, locale} = useSelector(state => state.app);
    return (
        <div className={'w-full h-[70px] flex flex-row justify-between items-center py-2 mt-5'}>
            <div>
                { isShowHomeButton === true ? <HomeButton /> : <></>}
            </div>
            <div>
                { isShowHomeButton === true ? <>
                    <p className={'text-white text-[18pt] text-left mb-0 font-semibold leading-6'}>{TranslatedAttribute(locale, menuInfo, 'title') || ''}</p>
                </> : <></>}

            </div>

            <div className={'flex flex-row justify-end'}>
                <Locale/>
            </div>
        </div>
    )
};
export default Header;