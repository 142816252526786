import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    answers: []
}

const feedback = createSlice({
    name: 'feedback',
    initialState,
    reducers: {
        setAnswers: (state, action) => {
            state.answers = [...state.answers, action.payload]
        },
        setAnswersDestroy: (state, action) => {
            state.answers = action.payload
        },
    }
})

export const { setAnswers, setAnswersDestroy } = feedback.actions
export default feedback.reducer