import {createSlice} from "@reduxjs/toolkit";
import {v4 as uuidv4} from 'uuid';
import Cookies from "universal-cookie";

const cookies = new Cookies(null);
const initialState = {
  theme: 'no_image_default',
  locale: cookies.get('MenuLocale', {path: '/'}) || 'tr',
  languages: {'tr': 'Türkçe', 'en': 'English', 'ru': 'Русский'},
  currency: 'TRY',
  currencySymbol: '₺',
  restaurant: {},
  productTree: [],
  activeCampaigns: {},
  sections: [],
  currentSection: {tables: []},
  menuInfo: [],
  basket: {
    id: "",
    items: []
  },
  tableId: '',
  menuId: '',
  restaurantId: '',
  isOrderAvailable: true,
  isShowFeedbackForm: true,
  isRequiredNoteScreen: true,
  isCookieAccept: false,
  isGoogleComment: true,
  googleCommentUrl: '',
  themeOption: 'option-1'
}

const app = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setTheme: (state, action) => {
      state.theme = action.payload;
    },
    setThemeOption: (state, action) => {
      state.themeOption = action.payload;
    },
    setLocale: (state, action) => {
      state.locale = action.payload;
    },
    setBasket: (state, action) => {
      const current_items = Object.assign([], state.basket.items)
      const id = current_items.length === 0 ? uuidv4() : state.basket.id
      current_items.push(action.payload)
      state.basket = Object.assign({id: id, items: current_items})
    },
    destroyAllBasket: (state) => {
      // state.basket = [];
      state.basket = {id: '', items: []};
    },
    setBasketAfterDeleteItem: (state, action) => {
      const id = action.payload.length === 0 ? '' : state.basket.id
      state.basket = {id: id, items: action.payload};
      // state.basket = [];
      // state.basket = action.payload
    },
    setTableId: (state, action) => {
      state.tableId = action.payload;
    },
    setMenuId: (state, action) => {
      state.menuId = action.payload;
    },
    setRestaurantId: (state, action) => {
      if (window.location.host === 'zeusbeachdidim.com') {
        state.restaurantId = 'b0b96f8a-9b28-4efb-9812-e38992946eec';
      } else {
        state.restaurantId = action.payload;
      }

    },
    setIsOrderAvailable: (state, action) => {
      state.isOrderAvailable = action.payload;
    },
    setIsShowFeedbackForm: (state, action) => {
      state.isShowFeedbackForm = action.payload;
    },
    setIsRequiredNoteScreen: (state, action) => {
      state.isRequiredNoteScreen = action.payload;
    },
    setMenuInfo: (state, action) => {
      state.menuInfo = action.payload;
    },
    setIsCookieAccept: (state, action) => {
      state.isCookieAccept = action.payload
    },
    setIsGoogleComment: (state, action) => {
      state.isGoogleComment = action.payload
    },
    setGoogleCommentUrl: (state, action) => {
      state.googleCommentUrl = action.payload
    }

  }
})

export const {
  setTheme,
  setThemeOption,
  setLocale,
  setBasket,
  destroyAllBasket,
  setTableId,
  setMenuId,
  setRestaurantId,
  setBasketAfterDeleteItem,
  setIsOrderAvailable,
  setIsShowFeedbackForm,
  setIsRequiredNoteScreen,
  setMenuInfo,
  setIsCookieAccept,
  setIsGoogleComment,
  setGoogleCommentUrl
} = app.actions
export default app.reducer