import {useSelector} from "react-redux";
import {createModal} from "../../utils/modal";
import allergenIcon from "../../assets/img/allergen_icon.png";
import React from "react";

const HeaderButton = () => {
    const {locale} = useSelector(state => state.app)

    return (
        <div>
            <div onClick={() => createModal('no_image_language')} className={'w-[55px] h-[55px] rounded-[55px] border-[7px] border-white bg-[#d3ecea] flex justify-center items-center shadow-md fixed z-20 right-[10px] top-[10px]'}>
                <img src={require(`../../../../assets/img/langs/${locale}.png`)} alt="" className={'w-[25px]'}/>
            </div>
            <div onClick={() => createModal('no_image_allergen_list')} className={'hidden w-[55px] h-[55px] rounded-[55px] border-[7px] border-white bg-[#d3ecea] flex justify-center items-center shadow-md fixed z-20 right-[75px] top-[10px]'}>
                <img src={allergenIcon} alt="" className={'w-[30px] h-[30px]'}/>
            </div>
        </div>
    )
}

export default HeaderButton;
